const initalState = {
  loading:false,
  assignButtonDisabled:false,
  addButtonDisabled:false,
  editButtonDisabled:false,
  error:"",
  plans :[],
  addedPlan :{},
  addSuccess:false,
  successMessage:""
};
const planReducer = (state = initalState, action)=>{
  switch (action.type){
    case "FETCH_PLANS_START":
      state = {
        ...state,
        loading:true,
        addSuccess:false,
        error:""
      };
      break;
    case "ADD_PLANS_START":
      state = {
        ...state,
        addButtonDisabled:true,
      };
      break;
    case "EDIT_PLAN_START":
      state = {
        ...state,
        editButtonDisabled:true,
      };
      break;
    case "ASSIGN_PLAN_TO_CUSTOMER_START":
      state = {
        ...state,
        assignButtonDisabled:true,
      };
      break;
    case "FETCH_PLANS_SUCCESS":
      state = {
        ...state,
        plans: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        addSuccess:false,
        loading: false
      };
      break;
    case "ASSIGN_PLAN_TO_CUSTOMER_SUCCESS":
      state = {
        ...state,
        // plans:[{ ...action.payload }, ...state.plans],
        // addedPlan:action.payload,
        // totalDocs: state.totalDocs + 1,
        showAddSuccessMessage:true,
        addSuccess:true,
        assignButtonDisabled:false
      };
      break;
    case "ADD_PLAN_SUCCESS":
      state = {
        ...state,
        plans:[{ ...action.payload }, ...state.plans],
        addedPlan:action.payload,
        totalDocs: state.totalDocs + 1,
        showAddSuccessMessage:true,
        addSuccess:true,
        addButtonDisabled:true
      };
      break;
    case "ADD_PLAN_FAIL":
      state = {
        ...state,
        addButtonDisabled: false,
      };
      break;
    case "ASSIGN_PLAN_TO_CUSTOMER_FAIL":
      state = {
        ...state,
        assignButtonDisabled: false,
      };
      break;
    case "ADD_MODAL_CLEAR":
      state = {
        ...state,
        addSuccess:false,
        showAddSuccessMessage:false,
        addButtonDisabled: false,
        editButtonDisabled: false
      };
      break;
    case "EDIT_PLAN_SUCCESS":
      state = {
        ...state,
        plans :state.plans.map(feeGroup=>{
          
          if (feeGroup._id === action.payload._id){
            return {
              ...feeGroup,
              ...action.payload,
              value:{ $numberDecimal : action.payload.value },
              minValue:{ $numberDecimal : action.payload.minValue },
              maxValue:{ $numberDecimal : action.payload.maxValue }
            };
          }
          else {
            return feeGroup;
          }
        }),
        showEditSuccessMessage :true,
        addSuccess:true,
        editButtonDisabled :true
      };
      break;
    case "EDIT_MODAL_CLEAR":
      state = {
        ...state,
        addSuccess:false,
        showEditSuccessMessage:false,
        editButtonDisabled :false
      };

      break;
    case "DELETE_PLAN_START":
      
      state = {
        ...state,
        deleteLoading:true
      };
    
      break;
    case "DELETE_PLAN_SUCCESS":
      
      state = {
        ...state,
        plans :state.plans.filter(feeGroup=> feeGroup._id !== action.payload.id),
        totalDocs : state.totalDocs - 1,
        showDeleteModal:true,
        addSuccess:false,
        deleteLoading:false
      };
    
      break;
    case "DELETE_MODAL_CLEAR":
      state = {
        ...state,
        showDeleteModal:false,
        addSuccess:false,
        deleteLoading:false
      };
      break;
    case "API_ERROR":
      state = {
        ...state,
        addSuccess:false,
        showAddSuccessMessage:false,
        addButtonDisabled: false,
        editButtonDisabled: false,
        error:action.payload.error
      };
      break;
    default :
      state = { ...state };
  }
  return state;
};
export default planReducer;