import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { 
  Form, FormGroup, Input, Col, Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./CreatePlan.styles.scss";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import { editPlanStart } from "store/actions";
import Loader from "components/Common/Loader";

const BaseForm = (props) => {
  const {
    setIsPublic,
    setIsEnabled,
    id,
    setIsVerificationReuired,
    isPublic,
    isEnabled,
    platforms,
    title,
    description,
    brokerGroup,
    price,
    initialBalance,
    setInitialBalance,
    isVerificationReuired,
    setBrokerGroup,
    setPlatforms,
    platformsData,
    setIsPriceActivated,
    isPriceActivated,
    setAmount,
    amount,
    setPayoutType,
    payoutType,
    payoutValue,
    assignType,
    setAssignType,
    referralClients,
    setReferralClients,
    allReferrals,
  } = props;
  const { t } = useTranslation();

  const { accountTypes } = useSelector(state => state.tradingAccountReducer);
  const { editButtonDisabled, addSuccess, addedPlan } = useSelector((state) => state.planReducer);
  // const [editButtonDisabled, seteditButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  async function updatePlan() {
    try {
      const data = {
        "isPriceActivated": isPriceActivated,
      };
      dispatch(editPlanStart(id, data));
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="my-5">
      <Row>
        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Title")}</h5>
            <div className="input-group w-100">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  @
                </span>
              </div>
              <div className="avFormInputWrapper">
                <AvField
                  name="title"
                  placeholder={`${t("Input Title")}`}
                  value={title}
                  type="text"
                  disabled={id}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Price")}</h5>
            <div className="w-100">
              <AvField
                name="price"
                placeholder={`${t("0")}`}
                type="number"
                value={price}
                disabled={id}
                style={{ width:"100%" }}
              />
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Initial Balance")}</h5>
            <div className="w-100">
              <AvField
                name="initialBalance"
                placeholder={`${t("0")}`}
                type="number"
                value={initialBalance}
                onChange={(e)=> setInitialBalance(e.target.value)}
                disabled={id}
                style={{ width:"100%" }}
              />
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Description")}</h5>
            <div className="w-100">
              <AvField
                name="description"
                placeholder={`${t("Input Description")}`}
                value={description}
                disabled={id}
                type="text"
              />
            </div>
          </div>
        </Col>

        {/* <Col md="12">
          <p className="sectionSeperator">{t("Assign Client")}</p>
        </Col> */}
        
        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Assign Type")}</h5>
            <div className="input-group w-100">
              <div className="avFormInputWrapper">
                <AvFieldSelect
                  name="assignType"
                  placeholder={"Choose Assign Type"}
                  className="w-100"
                  options={[
                    {
                      label: "All",
                      value: "all"
                    },
                    {
                      label:"Specific Clients",
                      value: "specificClient"
                    },
                    {
                      label:"Specific Referral",
                      value: "specificReferral"
                    },
                  ]}
                  value={assignType}
                  onChange={(e) => setAssignType(e)}
                  disabled={id}
                >
                </AvFieldSelect>
              </div>
            </div>
          </div>
        </Col>
        {
          assignType === "specificReferral" && 
          
          <Col md="12">
            <div className="mb-3 d-flex align-items-center gap-2">
              <h5 className="mb-2 createPlanInputTitle">{t("Referrals")}</h5>
              <div className="input-group w-100">
                <div className="avFormInputWrapper">
                  <AvFieldSelect
                    name="referralClients"
                    placeholder={"Choose Referral Clients"}
                    className="w-100"
                    options={allReferrals && allReferrals?.map(referral=>{
                      return {
                        label: `${referral?.firstName} ${referral?.lastName}`,
                        value: `${referral?._id}`
                      };
                    })}
                    value={referralClients}
                    onChange={(e) => setReferralClients(e)}
                    disabled={id}
                  >
                  </AvFieldSelect>
                </div>
              </div>
            </div>
          </Col>
        } */}

        <Col md="12">
          <p className="sectionSeperator">{t("Prize")}</p>
        </Col>
        
        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Group")}</h5>
            <div className="input-group w-100">
              <div className="avFormInputWrapper w-100">
                <AvFieldSelect
                  name="brokerGroupp"
                  className="w-100"
                  placeholder={"Choose Account Group"}
                  options={accountTypes?.map(acc => ({
                    label: `${acc.title}`,
                    value: `${acc._id}`
                  }))}
                  value={brokerGroup}
                  onChange={(e) => setBrokerGroup(e)}
                  disabled={id}
                >
                </AvFieldSelect>
              </div>
            </div>
          </div>
        </Col>

        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Amount")}</h5>
            <div className="w-100">
              <AvField
                name="amount"
                placeholder={`${t("0")}`}
                type="number"
                value={amount}
                onChange={(e)=> setAmount(e.target.value)}
                disabled={id}
                style={{ width:"100%" }}
              />
            </div>
          </div>
        </Col> */}

        {/* <Col md="12">
          <p className="sectionSeperator">{t("Payout")}</p>
        </Col> */}
        
        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Payout Type")}</h5>
            <div className="input-group w-100">
              <div className="avFormInputWrapper">
                <AvFieldSelect
                  name="payoutType"
                  placeholder={"Choose Payout Type"}
                  className="w-100"
                  options={[
                    {
                      label: "Fixed Price",
                      value: "fixed"
                    },
                    {
                      label:"Percentage",
                      value: "percentage"
                    },
                  ]}
                  value={payoutType}
                  onChange={(e) => setPayoutType(e)}
                  disabled={id}
                >
                </AvFieldSelect>
              </div>
            </div>
          </div>
        </Col>
        
        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Value")}</h5>
            <div className="w-100">
              <AvField
                name="payoutValue"
                placeholder={`${t("0")}`}
                type="number"
                value={payoutValue}
                disabled={id}
                style={{ width:"100%" }}
              />
            </div>
          </div>
        </Col> */}

        <Col md="12">
          <p className="sectionSeperator">{t("Broker")}</p>
        </Col>

        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Group")}</h5>
            <div className="input-group w-100">
              <div className="avFormInputWrapper">
                <AvFieldSelect
                  name="brokerGroupp"
                  className="w-100"
                  placeholder={"Choose Account Group"}
                  options={accountTypes?.map(acc => ({
                    label: `${acc.title}`,
                    value: `${acc._id}`
                  }))}
                  value={brokerGroup}
                  onChange={(e) => setBrokerGroup(e)}
                  disabled={id}
                >
                </AvFieldSelect>
              </div>
            </div>
          </div>
        </Col> */}

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Platforms")}</h5>
            <div className="avFormInputWrapper">
              <AvFieldSelect
                name="platform"
                ismulti={true}
                placeholder={"Choose Platform"}
                onChange={(e) => {
                  setPlatforms(e);
                }}
                options={platforms?.map(platform => ({
                  label: `${platform}`,
                  value: `${platform}`
                }))}
                value={platformsData}
                disabled={id}
              >
              </AvFieldSelect>
            </div>
          </div>
        </Col>

        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Currency")}</h5>
            <div className="input-group w-100">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  $
                </span>
              </div>
              <div className="avFormInputWrapper">
                <AvField
                  name="currency"
                  placeholder={`${t("Input Currency")}`}
                  type="price"
                />
              </div>
            </div>
          </div>
        </Col> */}
        
        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Help Post")}</h5>
            <div className="w-100">
              <AvField
                name="helpPost"
                placeholder={`${t("Input Help Post")}`}
                type="text"
              />
            </div>
          </div>
        </Col> */}

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Is Price Activated?")}</h5>
            <div className="w-100">
              <Form>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={isPriceActivated}
                    // disabled={id}
                    onClick={() => {
                      setIsPriceActivated(!isPriceActivated);
                    }}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Enabled?")}</h5>
            <div className="w-100">
              <Form>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={isEnabled}
                    disabled={id}
                    onClick={() => {
                      setIsEnabled(!isEnabled);
                    }}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Public?")}</h5>
            <div className="w-100">
              <Form>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={isPublic}
                    disabled={id}
                    onClick={() => {
                      setIsPublic(!isPublic);
                    }}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>

        {/* <Col md="12">
          <div className="mb-3 d-flex align-items-center gap-2">
            <h5 className="mb-2 createPlanInputTitle">{t("Verification?")}</h5>
            <div className="w-100">
              <Form>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={isVerificationReuired}
                    onClick={() => {
                      setIsVerificationReuired(!isVerificationReuired);
                    }}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col> */}
      </Row>
      <div
        className="btn-group "
        role="group"
        aria-labelledby="Basic outlined example"
      >
        {
          (id ) &&
            <button
              disabled={editButtonDisabled}
              type="button"
              className="btn btn-lg btn-primary btn_style  w-100 mx-4 px-4 text-white"
              onClick={() => updatePlan()}
              // onClick={() => {currentTab === "phases" ? setIsNav(false) : goToNextTab() }}
            >
              {
                !editButtonDisabled ? 
                  <><i className="fa fa-floppy-disk"></i> Update</>
                  : <Loader />
              }
            </button>
        }
      </div>
    </div>
  );
};

export default BaseForm;
