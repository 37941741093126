export const FETCH_FEE_CONFIGURATIONS_START = "FETCH_FEE_CONFIGURATIONS_START";
export const FETCH_FEE_CONFIGURATIONS_SUCCESS = "FETCH_FEE_CONFIGURATIONS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ADD_FEES_CONFIGURATIONS_START = "ADD_FEES_CONFIGURATIONS_START";
export const ADD_FEES_CONFIGURATION_SUCCESS = "ADD_FEES_CONFIGURATION_SUCCESS";
export const EDIT_FEE_CONFIGURATION_START = "EDIT_FEE_CONFIGURATION_START";
export const EDIT_FEE_CONFIGURATION_SUCCESS = "EDIT_FEE_CONFIGURATION_SUCCESS";
export const DELETE_FEE_CONFIGURATION_START = "DELETE_FEE_CONFIGURATION_START";
export const DELETE_FEE_CONFIGURATION_SUCCESS = "DELETE_FEE_CONFIGURATION_SUCCESS";
export const ADD_MODAL_CLEAR = "ADD_MODAL_CLEAR";
export const EDIT_MODAL_CLEAR = "EDIT_MODAL_CLEAR";
export const DELETE_MODAL_CLEAR = "DELETE_MODAL_CLEAR";