import React, { useState, useEffect } from "react";
import {
  useDispatch, connect
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Label
} from "reactstrap";
import { convertToIB } from "store/client/actions";
import { withTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import { AvForm } from "availity-reactstrap-validation";
import Select, { components } from "react-select";
import { assignCstomerToPlanStart, fetcPlansStart } from "store/actions";
const { Option } = components;

function AssignPlan(props) {
  const { clientData, isLead, t, kyc, id, convertToIbDetails } = props;
  // const {
  //   loading,
  // } = convertToIbDetails;
  const dispatch = useDispatch();

  const [linkClientModal, setLinkClientModal] = useState(false);
  const [planId, setPlanId] = useState();

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  useEffect(() => {
    if (convertToIbDetails?.clear && linkClientModal){
      toggleModal();
    }
  }, [convertToIbDetails?.clear]);

  const handleConfirm = () => {
    dispatch(convertToIB({ id }));
  };

  const handleAssignCustomer = (e, v)=>{
    dispatch(assignCstomerToPlanStart({
      planId,
      customerId: clientData?._id,
    }));
  };

  useEffect(()=>{
    loadPlans(1, 1000000);
  }, []);
  
  const loadPlans = (page, limit) => {
    dispatch(
      fetcPlansStart({
        limit,
        page,
        assignType: "specificClient",
      })
    );
  };

  const CustomOption = (props) => (
    <Option {...props}>
      {props.data.label}
    </Option>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
    }),
  };
  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        Assign Plan
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
          Assign Plan
        </ModalHeader>
        <ModalBody >
          <Container>
            <Row className="mb-3">
              <AvForm
                className="p-4"
                onValidSubmit={ (e, v) => {
                  handleAssignCustomer(e, v);
                }}
              >
                <Label>Plans</Label>
                <Select
                  type="text" 
                  label="Plans"
                  name="planId"
                  placeholder="Select From Plans"
                  // isOptionDisabled={(option) => option.value.asset == toAsset}
                  styles={customStyles}
                  components={{ Option: CustomOption }}
                  options = {props?.docs && props?.docs?.map((plan)=>{
                    return {
                      label: plan?.title,
                      value: plan?._id,
                    };
                  })}
                  onChange= {e=>{
                    setPlanId(e.value);
                  }}
                />
              
                <div className="mt-2 text-center">
                  <Button color="primary" disabled={!props?.assignButtonDisabled}>
                    {props?.assignButtonDisabled ? "Assign Plan" : <Loader/>}
                  </Button>
                  {/* // <Button color="primary">Assign Plan</Button> */}
                </div>
              
              </AvForm>
            </Row>
          </Container>
        </ModalBody>
        {/* <ModalFooter>
          <Button onClick={toggleModal} color="secondary">
            {t("No")}
          </Button> */}
        {/* {loading ? <Loader/> : (
          <Button onClick={handleConfirm} color="primary" disabled={isIb || !kyc}>
            {t("Yes")}
          </Button> */}
        {/* )} */}
        {/* </ModalFooter> */}
      </Modal>
    </React.Fragment >
  );
}

const mapStateToProps = (state)=>({
  docs: state?.planReducer?.plans || [],
  assignButtonDisabled: state?.planReducer?.assignButtonDisabled || [],
  assets: state?.assetReducer?.assets || [],
  convertWalletError: state?.planReducer?.convertWalletError,
  disableConvertWalletButton: state?.planReducer?.disableConvertWalletButton
});
export default connect(mapStateToProps, null)(AssignPlan);