import React, { useState } from "react";
import moment from "moment";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewInfo.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import demoAvatar from "assets/images/demo-avatar.jpg";
import { ReactComponent as TrueIcon } from "assets/img/icons/icon-true.svg";
import { ReactComponent as AccountDetailsIcon } from "assets/img/icons/account-details.svg";
import { ReactComponent as EyeIcon } from "assets/img/icons/eye-icon.svg";
import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";
function AccountOverviewInfo(props) {
  const { challenge } = props;
  const [showInvestorPass, setShowInvestorPass] = useState(false);
  const [showMasterPass, setShowMasterPass] = useState(false);
  return (
    
    <CardWrapper className="mb-5 glass-card shadow account-details__wrapper">
      <div className="account-details__info-wrapper">
        <div className="account-details__info-img-wrapper">
          <img 
            src={challenge?.customerId?.profileAvatar || demoAvatar} 
            alt="Account Image"
            className="account-details__info-img"
          />
          <div className="account-details__info-details-wrapper">
            <h2 className="account-details__info-details-title">{props.t(`Hello ${challenge?.customerId?.firstName} ${challenge?.customerId?.lastName}`)}</h2>
            <p className="account-details__info-details-description">{props.t("Now You Have Express Account")}</p>
            <ul className="account-details__info-list">
              <li className="account-details__info-list-item"><TrueIcon/>Plan: {challenge?.planId?.title}</li>
              <li className="account-details__info-list-item"><TrueIcon/>Challenge: {challenge?.title}</li>
              <li className="account-details__info-list-item"><TrueIcon/>Initial Balance: {challenge?.tradingAccountId?.type === "LIVE" ? challenge?.planId?.initialBalance : challenge?.tradingAccountId?.tradingCapital}</li>
              <li className="account-details__info-list-item"><TrueIcon/>Plan Type: {challenge?.tradingAccountId?.type}</li>
              <li className="account-details__info-list-item"><TrueIcon/>Account Type: {challenge?.tradingAccountId?.preference}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="account-details__section-wrapper">
        <h2 className="account-details__section-title">
          <AccountDetailsIcon/>
          {props.t("Account Details")}
        </h2>

        <div className="account-details__section-items-wrapper">

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Log In")}</p>
            <p className="account-details__section-item-value">
              {challenge?.tradingAccountId?.login}
              {/* <CopyIcon/> */}
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Investor Pass.")}</p>
            <p className="account-details__section-item-value">
              {
                showInvestorPass ? challenge?.tradingAccountId?.investorPassword : "*********"
              }
              <button type="button" className="account-details__section-item-show-button" onClick={()=> setShowInvestorPass(prevVal=>!prevVal)}>
                <EyeIcon/>
              </button>
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Master Pass.")}</p>
            <p className="account-details__section-item-value">
              {
                showMasterPass ? challenge?.tradingAccountId?.password : "*********"
              }
              <button type="button" className="account-details__section-item-show-button" onClick={()=> setShowMasterPass(prevVal=> !prevVal)}>
                <EyeIcon/>
              </button>
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Server")}</p>
            <p className="account-details__section-item-value">
              {/* {props.t("Growthnext-Server")} */}
              {challenge?.tradingAccountId?.platform}
            </p>
          </div>

          {/* <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Margin")}</p>
            <p className="account-details__section-item-value">
              {challenge?.margin}
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Margin Free")}</p>
            <p className="account-details__section-item-value">
              {challenge?.marginFree}
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Equity")}</p>
            <p className="account-details__section-item-value">
              {challenge?.equity}
            </p>
          </div>

          <div className="account-details__section-item-wrapper">
            <p className="account-details__section-item-text">{props.t("Balance")}</p>
            <p className="account-details__section-item-value">
              {challenge?.balance}
            </p>
          </div> */}

        </div>

      </div>

      <div className="account-details__trading-wrapper">
        <h2 className="account-details__section-title">
          <TradingCycleDetails/>
          {props.t("Trading Cycle Details")}
        </h2>
        {
          challenge?.dateType === "custom" ?
            <>
              <div className="account-details__trading-items-wrapper">
                <p className="account-details__trading-item-text">{props.t("Start Date")} : {moment(challenge?.startingDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                <p className="account-details__trading-item-text">{props.t("End Date")} : {moment(challenge?.endingDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
              </div>
            </>
            :
            <>
              <div className="account-details__trading-items-wrapper">
                <h3 className="account-details__trading-item-text">{props.t("Any Date")}</h3>
              </div>
            </>
        }
      </div>
    </CardWrapper>
  );
}
export default withTranslation()(AccountOverviewInfo);
