import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchChallengeGroupedByPlan (params){
  try {
    const result = await axioHelper.get(`/challenges/challenge-grouped?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}

export async function fetchChallenges (params){
  try {
    const result = await axioHelper.get(`/challenges?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}
export async function fetchChallenge (id){
  try {
    const result = await axioHelper.get(`/challenges/${id}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}
export async function fetchChallengeStatistics (){
  try {
    const result = await axioHelper.get("/challenges/statistics");
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}
export async function fetchCustomerchallenges (params){
  try {
    const result = await axioHelper.get(`/challenges/customer-subscriptions?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}
export async function updateChallenges ({ body, id }){

  const result = await axioHelper.patch(`/challenges/${id}`, body);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}
export async function addChallenges (payload){
  const result = await axioHelper.postFormData("/challenges", payload);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
  
}
export async function deleteChallenges ( id ){
  try {
    const result = await axioHelper.del(`/challenges/${id}`);
    return result;
  } catch (error){
    throw new Error("Error happened while deleting data");
  }
}