import React, { useState, useEffect } from "react";
import {
  useDispatch, connect
} from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import { editDiscountCoupoun } from "store/discountCoupouns/actions";

function DiscountCouppounsEditModal(props) {
  const { open, clients, user = {}, onClose } = props;
  const [submitState, setSubmitState] = useState(false);
  const dispatch = useDispatch();
  const handleEditDiscountCoupoun = (e, values) => {
    setSubmitState(true);
    dispatch(editDiscountCoupoun({
      id: user._id,
      values
    }));
    setTimeout(() => {
      setSubmitState(false);
    }, 2500);
  };

  const toggleEditModal = () => {
    onClose();
  };

  useEffect(() => {
    if (props.editClearingCounter > 0 && open ) {
      onClose();
    }
  }, [props.editSuccess]);

  return (
    <React.Fragment >
      {/* <Link to="#" className="btn btn-light" onClick={onClose}><i className="bx bx-plus me-1"></i> Add New</Link> */}
      <Modal isOpen={open} toggle={toggleEditModal} centered={true}>
        <ModalHeader toggle={toggleEditModal} tag="h4">
          Edit Discount Coupoun
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleEditDiscountCoupoun(e, v);
            }}
          >
            <div className="mb-3">
              <label >Select Type </label>
              <AvField
                type="select"
                name="type"
                errorMessage={props.t("please select type")}
                value={user?.type}
                validate={{ required: { value: true } }}
              >
                <option value="">{props.t("Select Type")}</option>
                <option value="fixed">{props.t("Fixed Price")}</option>
                <option value="percentage">{props.t("Percentage")}</option>
                
              </AvField>
            </div>
            <div className="mb-3">
              <AvField
                name="code"
                label={props.t("Code")}
                placeholder={props.t("Enter Code")}
                type="text"
                errorMessage={props.t("Enter Code")}
                value={user?.code}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="value"
                label={props.t("Value")}
                placeholder={props.t("Enter Value")}
                type="number"
                errorMessage={props.t("Enter Value")}
                value={user?.value}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="using_times"
                label={props.t("Used Times")}
                placeholder={props.t("Enter Used Times")}
                type="number"
                errorMessage={props.t("Enter Used Times")}
                value={user?.using_times}
                // validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <label >Select Customer </label>
              <AvField
                type="select"
                name="customerId"
                errorMessage={props.t("please select customer")}
                // validate={{ required: { value: true } }}
                value={user?.customerId?._id}
              >
                <option value="">{props.t("Select Customer")}</option>
                {clients?.map((row) => {
                  return (<option key={row._id} value={row._id}>{row.firstName} {row.lastName}</option>);
                })}
                
              </AvField>
            </div>
            <div className='text-center'>
              {
                props.addLoading
                  ?
                  <Loader />
                  :
                  <Button type="submit" color="primary" className="" disabled={submitState}>
                    {props.t("Edit")} 
                  </Button>
              }
            </div>
          </AvForm>
          {props.addError && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.addErrorDetails}
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  editLoading: state.discountCoupounsReducer.editLoading,
  addLoading: state.discountCoupounsReducer.addLoading,
  editResult: state.discountCoupounsReducer.editResult,
  editError: state.discountCoupounsReducer.editError,
  editSuccess: state.discountCoupounsReducer.editSuccess,
  editClearingCounter: state.discountCoupounsReducer.editClearingCounter,
  clients: state.clientReducer.clients || [],
  
});
export default connect(mapStateToProps, null)(withTranslation()(DiscountCouppounsEditModal));
