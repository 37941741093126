import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchFeeConfiguration (params){
  try {
    const result = await axioHelper.get(`/fee-configuration?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}

export async function updateFeeConfiguration ({ body, id }){
  const result = await axioHelper.patch(`/fee-configuration/${id}`, body);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}

export async function addFeeConfiguration (payload){
  const result = await axioHelper.post("/fee-configuration", payload);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}

export async function deleteFeeConfiguration ( id ){
  try {
    const result = await axioHelper.del(`/fee-configuration/${id}`);
    return result;
  } catch (error){
    throw new Error("Error happened while deleting data");
  }
}