/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, connect, useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import FeatherIcon from "feather-icons-react";

import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tr, Th, Td, Tbody } from "react-super-responsive-table";
import { useTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import { deletePlanStart, fetcPlansStart } from "store/plans/actions";
import TableLoader from "components/Common/TableLoader";
import Loader from "components/Common/Loader";
import CustomDropDown from "components/Common/CustomDropDown";
import PlansFilter from "./PlansFilter";

function Plan() {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  const { t } = useTranslation();
  const { loading, plans, totalDocs, deleteLoading, ...rest } = useSelector((state) => state.planReducer);

  const initFilteredValues = {
    title: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    recordId: "",
    totalProfit: "",
    dailyLoss: "",
    maxLoss: "",
    minTradingDays: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);

  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  const columns = [
    {
      dataField: "checkbox",
      text: (
        <input
          type="checkbox"
          id="check-all-deposits"
          onChange={() =>
            checkAllBoxes("check-all-deposits", ".deposit-checkbox")
          }
        />
      ),
    },
    {
      dataField: "recordId",
      text: t(" Id"),
    },
    {
      dataField: "title",
      text: t("Title"),
    },
    // {
    //   dataField: "currency",
    //   text: t("Currency"),
    // },
  
    {
      dataField: "price",
      text: t("Price"),
    },
  
    {
      dataField: "isEnabled",
      text: t("Enabled?"),
      formatter: (val) => <p>{val?.isEnabled ? "TRUE" : "FALSE"}</p>,
    },

    {
      dataField: "isPublic",
      text: t("Public?"),
      formatter: (val) => <p>{val?.isPublic ? "TRUE" : "FALSE"}</p>,
    },

    {
      dataField: "isFree",
      text: t("Free?"),
      formatter: (val) => <p>{val?.isFree ? "TRUE" : "FALSE"}</p>,
    },

    // {
    //   text: t("Phases Count"),
    // },
    {
      dataField: "actions",
      text: t("Actions"), 
      formatter: (val) => (
        <div>
          <button
            className="border-0 bg-transparent mx-2"
            onClick={()=> handleDelete(val?._id)}>
              {
                !deleteLoading ?
                  <i className="fa fa-trash"></i> 
                :
                  <Loader />
              }
          </button>
          <Link to={`/plans/update/${val?._id}`} className="">
          <i className="fa fa-eye"></i> 
          </Link>
        </div>
      ),
    },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  
  const handleDelete = (id) => {
    dispatch(
      deletePlanStart(id)
    );
  };
  
  const loadPlans = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetcPlansStart({
          limit,
          page,
          searchText: searchInput,
          filteredValues,
        })
      );
    } else {
      dispatch(
        fetcPlansStart({
          limit,
          page,
          filteredValues,
        })
      );
    }
  };
  useEffect(() => {
    loadPlans(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    searchInput,
    filteredValues
  ]);
  useEffect(() => {
    console.log("rest", rest, plans);
  }, [rest]);
  return (
    <React.Fragment>

      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary d-flex gap-2">
                    <p style={{ fontSize:"22px" }}>  {t("Plan")}</p>
                    <h6 style={{ marginTop:"14px" }}>{t("Tranding Plans and Phases")}</h6>
                    <h6 style={{
                    marginTop:"15px",
                    color:"#426A9E" 
                    } }>
                      {t("Need Help")}
                  </h6>
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center ">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={t("Search")}
                  />
                  <PlansFilter filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues} />
                  {/* <div>
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        {selected} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem value="LIVE" onClick={(e) => { setSelected(e.target.value) }}>Live</DropdownItem>
                        <DropdownItem value="DEMO" onClick={(e) => { setSelected(e.target.value) }}>Demo</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="btn-group " role="group"
                    aria-labelledby="Basic outlined example">
                      <Link to='/plans/create' type="button" className="btn btn-lg btn-outline-primary btn_style  w-100 mx-4 px-4">
                          <i className="fa fa-plus mx-2"></i> {t("New")}
                      </Link>
                  </div>
                  {/* <div className="btn-group " role="group"
                    aria-labelledby="Basic outlined example">
                      <button type="button" className="btn btn-lg btn-outline-primary btn_style w-100 ">
                          <i className="fa fa-bars mx-3"></i> {t("Recorder Phases")}
                      </button>
                  </div> */}
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-5"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>
                    {totalDocs === 0 ? (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {loading && <TableLoader colSpan={4} />}
                        {!loading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {loading && <TableLoader colSpan={4} />}
                        {!loading &&
                          plans?.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className={`pt-4 ${column.dataField === "dropdown" &&
                                    "d-flex justify-content-center"
                                    }`}
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="deposit-checkbox"
                                      type="checkbox"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {column.dataField === "dropdown" && (
                                    <CustomDropDown
                                      // permission={
                                      //   depositsPermissions.actions
                                      // }
                                      status={row.status}
                                      // approve={() => depositApprove(row)}
                                      // reject={() => depositReject(row)}
                                    />
                                  )}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...rest}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadPlans}
                    docs={plans}
                    totalDocs={totalDocs}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* {<DetailsModal rawData={selectedContent} open={detailsModal} onClose={() => setDetailsModal(false)} />} */}
      {/* {selectedContent && (
        <ReceiptModal
          content={selectedContent}
          open={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
      )} */}
    </React.Fragment>
  );
}


export default Plan;
