import React, { 
  useState,
  useEffect,
  useCallback,
} from "react";
import { debounce } from "lodash";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useDispatch, connect
} from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  Label,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
// import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";
// import { sassFalse } from "sass";
import Loader from "components/Common/Loader";
import { fetchClientsStart } from "store/client/actions";
import { addDiscountCoupoun } from "store/discountCoupouns/actions";

function DiscountCouppounsAddModal(props) {
  const [addModal, setAddDiscountCoupounModal] = useState(false);
  const [submitState, setSubmitState] = useState(false);
  const dispatch = useDispatch();
  const { clients } = props;
  const { create } = props.userPermissions;
  const toggleAddModal = () => {
    setAddDiscountCoupounModal(!addModal);
  };
  const handleAddDiscountCoupoun = (e, values) => {
    setSubmitState(true);
    dispatch(addDiscountCoupoun(values));
    setTimeout(() => {
      setSubmitState(false);
    }, 2500);
  };

  useEffect(() => {
    if (props.clearingCounter > 0 && addModal) {
      setAddDiscountCoupounModal(false);
    }
  }, [props.addSuccess]);

  useEffect(() => {
    loadClients(1, 1000000000000000);
  }, []);

  const loadClients = (page, limit) => {
    dispatch(
      fetchClientsStart({
        limit,
        page
      })
    );
  };

  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> Add New Discount Coupoun</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Discount Coupoun")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              // if (!v.country) v.country = "any";
              handleAddDiscountCoupoun(e, v);
              // console.log("countries: ", countries);
            }}
          >
            <div className="mb-3">
              <label >Select Type </label>
              <AvField
                type="select"
                name="type"
                errorMessage={props.t("please select type")}
                validate={{ required: { value: true } }}
              >
                <option value="">{props.t("Select Type")}</option>
                <option value="fixed">{props.t("Fixed Price")}</option>
                <option value="percentage">{props.t("Percentage")}</option>
                
              </AvField>
            </div>
            <div className="mb-3">
              <AvField
                name="code"
                label={props.t("Code")}
                placeholder={props.t("Enter Code")}
                type="text"
                errorMessage={props.t("Enter Code")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="value"
                label={props.t("Value")}
                placeholder={props.t("Enter Value")}
                type="number"
                errorMessage={props.t("Enter Value")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="using_times"
                label={props.t("Used Times")}
                placeholder={props.t("Enter Used Times")}
                type="number"
                errorMessage={props.t("Enter Used Times")}
                // validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <label >Select Customer </label>
              <AvField
                type="select"
                name="customerId"
                errorMessage={props.t("please select customer")}
                // validate={{ required: { value: true } }}
              >
                <option value="">{props.t("Select Customer")}</option>
                {clients?.map((row) => {
                  return (<option key={row._id} value={row._id}>{row.firstName} {row.lastName}</option>);
                })}
                
              </AvField>
            </div>
            <div className='text-center'>
              {
                props.addLoading
                  ?
                  <Loader />
                  :
                  <Button type="submit" color="primary" className="" disabled={submitState}>
                    {props.t("Add")} 
                  </Button>
              }
            </div>
          </AvForm>
          {props.addError && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.addErrorDetails}
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  addLoading: state.discountCoupounsReducer.addLoading,
  addErrorDetails: state.discountCoupounsReducer.addErrorDetails,
  addSuccess: state.discountCoupounsReducer.addSuccess,
  addError: state.discountCoupounsReducer.addError,
  clearingCounter: state.discountCoupounsReducer.clearingCounter,
  userPermissions: state.Profile.userPermissions,
  countries: state.dictionaryReducer.countries || [],
  clients: state.clientReducer.clients || [],
});
export default connect(mapStateToProps, null)(withTranslation()(DiscountCouppounsAddModal));
