import { 
  takeEvery, 
  call, 
  put,
  delay
} from "redux-saga/effects";
import { 
  FETCH_FEE_CONFIGURATIONS_START,
  ADD_FEES_CONFIGURATIONS_START,
  EDIT_FEE_CONFIGURATION_START,
  DELETE_FEE_CONFIGURATION_START
} from "./actionsType";
import { 
  fetchFeeConfigurationsSuccess,
  apiError,
  addFeeConfigurationSuccess,
  editFeeConfigurationSuccess,
  deleteFeeConfigurationSuccess,
  addModalClear,
  editModalClear,
  deleteModalClear
} from "./actions";
import { showSuccessNotification } from "store/notifications/actions";
import { 
  addFeeConfiguration, deleteFeeConfiguration, fetchFeeConfiguration, updateFeeConfiguration
} from "apis/fee-configuration";

function * getFeeConfiguration({ payload :{ params } }){
  try {
    const result = yield call(fetchFeeConfiguration, params);
    yield put(fetchFeeConfigurationsSuccess(result));
  } catch (error){
    yield put(apiError(error));
  }
}
function * addNewFeesConfiguration ({ payload }){
  try {
    const data = yield call(addFeeConfiguration, payload);
    const { status, result } = data;
    if (status === true){
      yield put(addFeeConfigurationSuccess(result));
      yield put(showSuccessNotification("Fee Configuration has been added successfully!"));
      yield delay(1000);
      yield put(addModalClear());
    }
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * editFeeConfiguration({ payload }){
  try {
    yield call(updateFeeConfiguration, payload);
    const { body, id } = payload;
    yield put(editFeeConfigurationSuccess({
      _id:id, 
      ...body
    }));
    yield put(editModalClear());
    yield put(showSuccessNotification("Fee Configuration has been updated successfully!"));
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * deleteFeeConfigurations ({ payload : { id } }){
  try {
    yield call(deleteFeeConfiguration, id);
    yield put(deleteFeeConfigurationSuccess(id));
    yield put(showSuccessNotification("Fee Configuration has been deleted successfully!"));
    yield delay(1000);
    yield put(deleteModalClear());
  } catch (error){
    yield put(apiError(error));
    yield delay(1000);
    yield put(apiError(""));
  }
}
function * feeConfigurationSaga(){
  yield takeEvery(FETCH_FEE_CONFIGURATIONS_START, getFeeConfiguration);
  yield takeEvery (ADD_FEES_CONFIGURATIONS_START, addNewFeesConfiguration);
  yield takeEvery(EDIT_FEE_CONFIGURATION_START, editFeeConfiguration);
  yield takeEvery (DELETE_FEE_CONFIGURATION_START, deleteFeeConfigurations);
}
export default feeConfigurationSaga;