export const FETCH_PLANS_START = "FETCH_PLANS_START";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ADD_PLANS_START = "ADD_PLANS_START";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ASSIGN_PLAN_TO_CUSTOMER_START = "ASSIGN_PLAN_TO_CUSTOMER_START";
export const ASSIGN_PLAN_TO_CUSTOMER_SUCCESS = "ASSIGN_PLAN_TO_CUSTOMER_SUCCESS";
export const ASSIGN_PLAN_TO_CUSTOMER_FAIL = "ASSIGN_PLAN_TO_CUSTOMER_FAIL";
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL";
export const EDIT_PLAN_START = "EDIT_PLAN_START";
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS";
export const DELETE_PLAN_START = "DELETE_PLAN_START";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const ADD_MODAL_CLEAR = "ADD_MODAL_CLEAR";
export const EDIT_MODAL_CLEAR = "EDIT_MODAL_CLEAR";
export const DELETE_MODAL_CLEAR = "DELETE_MODAL_CLEAR";