import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { 
  Form, FormGroup, Input, Col, Row, Label,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./CreatePlan.styles.scss";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { editPlanStart } from "store/actions";
import Loader from "components/Common/Loader";

const PhasesForm = (props) => {
  const {
    id,
    phases,
    handleChangePhaseData,
    removePhase,
    addPhase,
    initialBalance,
  } = props;
  const { t } = useTranslation();
  const { accountTypes } = useSelector(state => state.tradingAccountReducer);
  const { editButtonDisabled, addSuccess, addedPlan } = useSelector((state) => state.planReducer);
  // const [editButtonDisabled, seteditButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  // async function updatePlan() {
  //   try {
  //     const formData = new FormData();
  //     phases?.forEach((phase, index)=>{
  //       phase?.phase_title && formData.append(`phases[${index}][title]`, phase?.phase_title);
  //       phase?.isReadOnlyIfFailed && formData.append(`phases[${index}][isReadOnlyIfFailed]`, phase?.isReadOnlyIfFailed);
  //       phase?.calculateDailyLossFrom && formData.append(`phases[${index}][calculateDailyLossFrom]`, phase?.calculateDailyLossFrom);
  //       phase?.inactiveDays && formData.append(`phases[${index}][inactiveDays]`, phase?.inactiveDays);
  //       phase?.brokerGroup && formData.append(`phases[${index}][brokerGroup]`, phase?.brokerGroup);
  //       phase?.brokerSize && formData.append(`phases[${index}][brokerSize]`, phase?.brokerSize);
  //       phase?.targetDailyLoss && formData.append(`phases[${index}][targetDailyLoss]`, (initialBalance * (phase?.targetDailyLoss / 100)));
  //       phase?.targetMaxLoss && formData.append(`phases[${index}][targetMaxLoss]`, (initialBalance * (phase?.targetMaxLoss / 100)));
  //       phase?.targetProfitLoss && formData.append(`phases[${index}][targetProfitLoss]`, (initialBalance * (phase?.targetProfitLoss / 100)));
  //       phase?.targetMinTradingDays && formData.append(`phases[${index}][targetMinTradingDays]`, phase?.targetMinTradingDays);
  //       phase?.targetMaxTradingDays && formData.append(`phases[${index}][targetMaxTradingDays]`, phase?.targetMaxTradingDays);
  //       phase?.targetMaxPositions && formData.append(`phases[${index}][targetMaxPositions]`, phase?.targetMaxPositions);
  //       phase?.targetMaxLots && formData.append(`phases[${index}][targetMaxLots]`, phase?.targetMaxLots);
        
  //       phase?.targetMaxLots && formData.append(`phases[${index}][dateType]`, phase?.dateType);
  //       phase?.targetMaxLots && formData.append(`phases[${index}][startingDate]`, phase?.startingDate);
  //       phase?.targetMaxLots && formData.append(`phases[${index}][endingDate]`, phase?.endingDate);
        
  //       phase?.targetIsExpertProhibited && formData.append(`phases[${index}][targetIsExpertProhibited]`, phase?.targetIsExpertProhibited);
  //     });
  //     dispatch(editPlanStart(id, formData));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  return (
    <div className="my-5">
      {
        phases && phases?.map((phase, index) => (
          <Row key={phase?.id}>
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Title")}</h5>
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="phase_title"
                      placeholder={`${t("Input Title")}`}
                      type="text"
                      value={phase?.title}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Withdrawable")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="isWritable"
                        type="switch"
                        checked={phase?.isWritable}
                        onClick={(e) => handleChangePhaseData(e.target.name, !phase?.isWritable, index)}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col> */}

            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Verification")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="isVerificationRequired"
                        type="switch"
                        checked={phase?.isVerificationRequired}
                        onClick={(e) => handleChangePhaseData(e.target.name, !phase?.isVerificationRequired, index)}
                      />
                    </FormGroup>
                    <Label>{t("Manual verification is required for the Passed Objectives")}</Label>
                  </Form>
                </div>
              </div>
            </Col> */}

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Read Only If Failed")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="isReadOnlyIfFailed"
                        type="switch"
                        checked={phase?.isReadOnlyIfFailed}
                        disabled={id}
                        onClick={(e) => handleChangePhaseData(e.target.name, !phase?.isReadOnlyIfFailed, index)}
                      />
                    </FormGroup>
                    <Label>{t("Put account to read-only state if Phase is failed")}</Label>
                  </Form>
                </div>
              </div>
            </Col>
            
            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Calculate Days As")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup className="d-flex align-items-center gap-3">
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDaysAs"
                          id={`traded${index}`}
                          checked={phase?.calculateDaysAs === "traded"}
                          value= "traded"
                          onClick={(e) => handleChangePhaseData(e.target.name, "traded", index)}
                        />
                        <Label htmlFor="traded" className="mb-0">{t("Traded")}</Label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDaysAs"
                          id={`calender${index}`}
                          checked={phase?.calculateDaysAs === "calender"}
                          value= "calender"
                          onClick={(e) => handleChangePhaseData(e.target.name, "calender", index)}
                        />
                        <Label htmlFor="calender" className="mb-0">{t("Calender")}</Label>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col> */}
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Calculate Daily Loss From")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup className="d-flex align-items-center gap-3">
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDailyLossFrom"
                          id={`initial_balance${index}`}
                          checked={phase?.calculateDailyLossFrom === "initial_balance"}
                          value= "initial_balance"
                          disabled={id}
                          onClick={(e) => handleChangePhaseData(e.target.name, "initial_balance", index)}
                        />
                        <Label htmlFor="initial_balance" className="mb-0">{t("Initial Balance")}</Label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDailyLossFrom"
                          id={`current_balance${index}`}
                          checked={phase?.calculateDailyLossFrom === "current_balance"}
                          value= "current_balance"
                          disabled={id}
                          onClick={(e) => handleChangePhaseData(e.target.name, "current_balance", index)}
                        />
                        <Label htmlFor="current_balance" className="mb-0">{t("Current Balance")}</Label>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Inactivity Days")}</h5>
                <div className="w-100">
                  <AvField
                    name="inactiveDays"
                    placeholder={`${t("0")}`}
                    type="number"
                    value={phase?.inactiveDays}
                    disabled={id}
                    onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    style={{ width:"100%" }}
                  />
                </div>
              </div>
            </Col>
            
            <Col md="12">
              <p className="sectionSeperator">{t("Broker")}</p>
            </Col>
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Group")}</h5>
                <div className="input-group w-100">
                  <div className="avFormInputWrapper">
                    <AvFieldSelect
                      name="brokerGroup"
                      placeholder={"Choose Account Group"}
                      options={accountTypes?.map(acc => ({
                        label: `${acc.title}`,
                        value: `${acc._id}`
                      }))}
                      value={phase?.brokerGroup}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData("brokerGroup", e, index)}
                    >
                    </AvFieldSelect>
                  </div>
                </div>
              </div>
            </Col>
            
            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Size")}</h5>
                <div className="input-group w-100">
                  <div className="avFormInputWrapper">
                    <AvField
                      name="brokerSize"
                      placeholder={`${t("Input Size")}`}
                      type="number"
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col> */}

            <Col md="12">
              <p className="sectionSeperator">{t("Starting/Ending Date")}</p>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Date Type")}</h5>
                <div className="input-group w-100">
                  <div className="avFormInputWrapper">
                    <AvFieldSelect
                      name="dateType"
                      placeholder={"Choose Date Type"}
                      options={[
                        {
                          value: "any",
                          label: "Any Date"
                        },
                        {
                          value: "custom",
                          label: "Custom Date"
                        },
                      ]}
                      value={phase?.dateType}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData("dateType", e, index)}
                    >
                    </AvFieldSelect>
                  </div>
                </div>
              </div>
            </Col>
            
            {
              phase?.dateType === "custom" && 
              <>
                <Col md="12">
                  <div className="mb-3 d-flex align-items-center gap-2">
                    <h5 className="mb-2 createPlanInputTitle">{t("Starting Date")}</h5>
                    <div className="input-group w-100">
                      <div className="avFormInputWrapper">
                        <AvField
                          name="startingDate"
                          placeholder={`${t("Input Starting Day")}`}
                          type="datetime-local"
                          value={(moment(phase?.startingDate).format("YYYY-MM-DDTHH:MM"))}
                          disabled={id}
                          onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                
                <Col md="12">
                  <div className="mb-3 d-flex align-items-center gap-2">
                    <h5 className="mb-2 createPlanInputTitle">{t("Ending Date")}</h5>
                    <div className="input-group w-100">
                      <div className="avFormInputWrapper">
                        <AvField
                          name="endingDate"
                          placeholder={`${t("Input Ending Date")}`}
                          type="datetime-local"
                          value={(moment(phase?.endingDate).format("YYYY-MM-DDTHH:MM"))}
                          disabled={id}
                          onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            }

            <Col md="12">
              <p className="sectionSeperator">{t("Trading Objective Rules/Targets")}</p>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Daily Loss")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetDailyLoss"
                      placeholder={`${t("Input Daily Loss")}`}
                      type="number"
                      value={phase?.targetDailyLoss}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                      className="mb-0"
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {initialBalance * (phase?.targetDailyLoss / 100)} USD
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Max Loss")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMaxLoss"
                      placeholder={`${t("Input Max Loss")}`}
                      type="number"
                      value={phase?.targetMaxLoss}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {initialBalance * (phase?.targetMaxLoss / 100)} USD
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Profit")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetProfitLoss"
                      placeholder={`${t("Input Profit")}`}
                      type="number"
                      value={phase?.targetProfitLoss}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {initialBalance * (phase?.targetProfitLoss / 100)} USD
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Min Trading Days")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Day
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMinTradingDays"
                      placeholder={`${t("Input Min Trading Days")}`}
                      type="number"
                      value={phase?.targetMinTradingDays}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {phase?.targetMinTradingDays} Days
                  </div>
                </div>
              </div>
            </Col>
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Max Trading Days")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Day
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMaxTradingDays"
                      placeholder={`${t("Input Max Trading Days")}`}
                      type="number"
                      value={phase?.targetMaxTradingDays}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {phase?.targetMaxTradingDays} Days
                  </div>
                </div>
              </div>
            </Col>
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Max Positions")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Position
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMaxPositions"
                      placeholder={`${t("Input Max Positions")}`}
                      type="number"
                      value={phase?.targetMaxPositions}
                      disabled={id}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {phase?.targetMaxPositions} Positions
                  </div>
                </div>
              </div>
            </Col>
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Max Lots")}</h5>
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMaxLots"
                      placeholder={`${t("Input Max Lots")}`}
                      type="number"
                      value={phase?.targetMaxLots}
                      onChange={(e) => handleChangePhaseData(e.target.name, e.target.value, index)}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Is Stoploss Required")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="targetIsStopLossReuired"
                        type="switch"
                        checked={phase?.targetIsStopLossReuired}
                        onClick={(e) => handleChangePhaseData(e.target.name, !phase?.targetIsStopLossReuired, index)}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col> */}

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Expert Prohibited")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="targetIsExpertProhibited"
                        type="switch"
                        checked={phase?.targetIsExpertProhibited}
                        disabled={id}
                        onClick={(e) => handleChangePhaseData(e.target.name, !phase?.targetIsExpertProhibited, index)}
                      />
                    </FormGroup>
                    {/* <Label>{t("Put account to read-only state if Phase is failed")}</Label> */}
                  </Form>
                </div>
              </div>
            </Col>
            {
              !id && 
              <Col md="12">
                <button
                  type="button"
                  className="btn btn-danger d-flex ms-auto mb-2 px-4"
                  onClick={()=>removePhase(phase?.id)}
                >
                  {t("Remove Phase")}
                </button>
              </Col>
            }
            <hr/>
          </Row>
        ))
      }
      {/* <div
        className="btn-group "
        role="group"
        aria-labelledby="Basic outlined example"
      >
        {
          (id ) &&
            <button
              disabled={editButtonDisabled}
              type="button"
              className="btn btn-lg btn-primary btn_style  w-100 mx-4 px-4 text-white"
              onClick={() => updatePlan()}
              // onClick={() => {currentTab === "phases" ? setIsNav(false) : goToNextTab() }}
            >
              {
                !editButtonDisabled ? 
                  <><i className="fa fa-floppy-disk"></i> Update</>
                  : <Loader />
              }
            </button>
        }
      </div> */}
      {!id && <button type="button" onClick={addPhase} className="btn btn-success d-flex ms-auto my-2 px-4">{t("Add Phase")}</button>}
    </div>
  );
};

export default PhasesForm;
