import PlanList from "common/Plan/Plan";
import React from "react";

const Plan = () => {
  return (
    <div>
      <PlanList />
    </div>
  );
};

export default Plan;