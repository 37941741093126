import {
  FETCH_DISCOUNT_COUPOUNS_START,
  FETCH_DISCOUNT_COUPOUNS_SUCCESS,
  FETCH_DISCOUNT_COUPOUNS_ERROR,
  ADD_DISCOUNT_COUPOUNS_START,
  ADD_DISCOUNT_COUPOUNS_SUCCESS,
  ADD_DISCOUNT_COUPOUNS_ERROR,
  ADD_DISCOUNT_COUPOUN_CLEAR,

  FETCH_DISCOUNT_COUPOUNS_ROLES_START,
  FETCH_DISCOUNT_COUPOUNS_ROLES_SUCCESS,
  FETCH_DISCOUNT_COUPOUNS_ROLES_ERROR,

  EDIT_DISCOUNT_COUPOUNS_PASS_START,
  EDIT_DISCOUNT_COUPOUNS_START,
  EDIT_DISCOUNT_COUPOUNS_DONE,
  EDIT_DISCOUNT_COUPOUN_CLEAR,
  EDIT_DISCOUNT_COUPOUNS_ERROR,

  DELETE_DISCOUNT_COUPOUNS_START,
  DELETE_DISCOUNT_COUPOUNS_DONE,
  GET_ASSIGNED_DISCOUNT_COUPOUNS_START,
  GET_ASSIGNED_DISCOUNT_COUPOUNS_SUCCESS,
  ASSIGN_AGENT_START,
  ASSIGN_AGENT_COMPLETE,
  ASSIGN_AGENT_CLEAR,
  ASSIGN_AGENT_ERROR,
  GET_ASSIGNED_DISCOUNT_COUPOUNS_FAIL,
  DISCOUNT_COUPOUN_DISABLE_2FA_START,
  EDIT_TARGET_START,
  EDIT_TARGET_SUCCESS,
  EDIT_TARGET_FAIL,
} from "./actionTypes";

export const fetchDiscountCoupouns = (params = {}) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_START,
    payload: params
  };
};

export const fetchDiscountCoupounsSuccess = (data) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_SUCCESS,
    payload: data
  };
};
export const fetchDiscountCoupounsError = (error) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_ERROR,
    payload: { error }
  };
};

export const fetchDiscountCoupounsRoles = (params = {}) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_ROLES_START,
    payload: params
  };
};

export const fetchDiscountCoupounsRolesSuccess = (data) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_ROLES_SUCCESS,
    payload: data
  };
};
export const fetchDiscountCoupounsRolesError = (error) => {
  return {
    type: FETCH_DISCOUNT_COUPOUNS_ROLES_ERROR,
    payload: { error }
  };
};

export const addDiscountCoupoun = (params = {}) => {
  return {
    type: ADD_DISCOUNT_COUPOUNS_START,
    payload: params
  };
};
export const addDiscountCoupounSuccess = (data) => {
  return {
    type: ADD_DISCOUNT_COUPOUNS_SUCCESS,
    payload: data
  };
};
export const addDiscountCoupounError = (error) => {
  return {
    type: ADD_DISCOUNT_COUPOUNS_ERROR,
    payload: error && error.message || "",
  };
};
export const addDiscountCoupounClear = (data) => {
  return {
    type: ADD_DISCOUNT_COUPOUN_CLEAR,
    payload: data
  };
};

export const editDiscountCoupoun = (params = {}) => {
  return {
    type: EDIT_DISCOUNT_COUPOUNS_START,
    payload: params
  };
};
export const editDiscountCoupounPass = (params = {}) => {
  return {
    type: EDIT_DISCOUNT_COUPOUNS_PASS_START,
    payload: params
  };
};
export const editDiscountCoupounDone = (data) => {
  return {
    type: EDIT_DISCOUNT_COUPOUNS_DONE,
    payload: data
  };
};
export const editDiscountCoupounError = (data) => {
  return {
    type: EDIT_DISCOUNT_COUPOUNS_ERROR,
    payload: data
  };
};
export const editDiscountCoupounClear = (data) => {
  return {
    type: EDIT_DISCOUNT_COUPOUN_CLEAR,
    payload: data
  };
};

export const deleteDiscountCoupouns = (params = {}) => {
  return {
    type: DELETE_DISCOUNT_COUPOUNS_START,
    payload: params
  };
};
export const deleteDiscountCoupounDone = (data) => {
  return {
    type: DELETE_DISCOUNT_COUPOUNS_DONE,
    payload: data
  };
};
export const getSalesAgentsStart = (params = {}) => {
  return {
    type: GET_ASSIGNED_DISCOUNT_COUPOUNS_START,
    payload: params
  };
};
export const getSalesAgentsSuccess = (data) => {
  return {
    type: GET_ASSIGNED_DISCOUNT_COUPOUNS_SUCCESS,
    payload: data
  };
};
export const getSalesAgentsFail = (data) => {
  return {
    type: GET_ASSIGNED_DISCOUNT_COUPOUNS_FAIL,
  };
};
export const assignAgentStart = (params = {}) => {
  return {
    type: ASSIGN_AGENT_START,
    payload: params
  };
};
export const assignAgentSuccess = (data) => {
  return {
    type: ASSIGN_AGENT_COMPLETE,
    payload: data
  };
};
export const assignAgentClear = (data) => {
  return {
    type: ASSIGN_AGENT_CLEAR,
    payload: data
  };
};
export const assignAgentError = (data) => {
  return {
    type: ASSIGN_AGENT_ERROR,
    payload: data
  };
};
export const disable2FA = (params) => {
  return {
    type: DISCOUNT_COUPOUN_DISABLE_2FA_START,
    payload: params
  };
};
export const editTargetStart = (params = {}) => {
  return {
    type: EDIT_TARGET_START,
    payload: params
  };
};
export const editTargetSuccess = (data) => {
  return {
    type: EDIT_TARGET_SUCCESS,
    payload: data
  };
};
export const editTargetFail = (data) => {
  return {
    type: EDIT_TARGET_FAIL,
    payload: data
  };
};
