import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { memo, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Alert,
  Col, Row
} from "reactstrap";

function SubMasterAgreementForm({
  isValid, setIsValid, customerId, setPayload
}) {
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { products } = useSelector((state) => state?.ibAgreements);
  const { t } = useTranslation();
  const markups = useSelector((state) => state.dictionaryReducer.markups);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [agreement, setAgreement] = React.useReducer(
    (state, action) => {
      console.log(action);
      switch (action.type) {
        case "TITLE":
          return {
            ...state,
            title: action.value
          };
        case "INIT_AGREEMENT":
          return action.payload.agreement;
        case "UPDATE_TOTAL_REBATE":
          return {
            ...state,
            totals: state.totals.map((total) => {
              if (total.accountTypeId === action.payload.accountTypeId) {
                return {
                  ...total,
                  rebate: parseInt(action.payload.rebate),
                };
              }
              return total;
            }),
          };
        case "UPDATE_TOTAL_COMMISSION":
          return {
            ...state,
            totals: state.totals.map((total) => {
              if (total.accountTypeId === action.payload.accountTypeId) {
                return {
                  ...total,
                  commission: parseInt(action.payload.commission),
                };
              }
              return total;
            }),
          };
        case "INIT_MEMBERS": 
          return {
            ...state,
            members: action.payload,
          };
        case "UPDATE_MEMBER_REBATE_TOTAL":
          return {
            ...state,
            members: state.members.map((member) => {
              if (member.customerId === action.payload.customerId) {
                console.log(member, action.payload, "member");
                return {
                  ...member,
                  values: member.values.map((val) => {
                    if (val.accountTypeId === action.payload.accountTypeId) {
                      return {
                        ...val,
                        rebate: parseInt(action.payload.rebate),
                        products: Object.keys(val?.products ?? {}).reduce((acc, key) => {
                          return {
                            ...acc,
                            [key]: {
                              ...val?.products[key],
                              rebate: parseInt(action.payload.rebate),
                            }
                          };
                        }, {})
                      };
                    }
                    return val;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_MEMBER_COMMISSION_TOTAL":
          return {
            ...state,
            members: state.members.map((member) => {
              if (member.customerId === action.payload.customerId) {
                return {
                  ...member,
                  values: member.values.map((val) => {
                    if (val.accountTypeId === action.payload.accountTypeId) {
                      return {
                        ...val,
                        commission: parseInt(action.payload.commission),
                        products: Object.keys(val?.products ?? {}).reduce((acc, key) => {
                          return {
                            ...acc,
                            [key]: {
                              ...val?.products[key],
                              commission: parseInt(action.payload.commission),
                            }
                          };
                        }, {})
                      };
                    }
                    return val;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_MEMBER_REBATE_PRODUCT":
          return {
            ...state,
            members: state.members.map((member) => {
              if (member.customerId === action.payload.customerId) {
                return {
                  ...member,
                  values: member.values.map((val) => {
                    if (val.accountTypeId === action.payload.accountTypeId) {
                      return {
                        ...val,
                        products: Object.keys(val?.products ?? {}).reduce((acc, key) => {
                          
                          if (key === action.payload.product) {
                            return {
                              ...acc,
                              [key]: {
                                ...val?.products[key],
                                rebate: parseInt(action.payload.rebate),
                              }
                            };
                          }
                          return acc;
                        }, val?.products ?? {})
                      };
                    }
                    return val;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_MEMBER_COMMISSION_PRODUCT":
          return {
            ...state,
            members: state.members.map((member) => {
              if (member.customerId === action.payload.customerId) {
                return {
                  ...member,
                  values: member.values.map((val) => {
                    if (val.accountTypeId === action.payload.accountTypeId) {
                      return {
                        ...val,
                        products: Object.keys(val?.products ?? {}).reduce((acc, key) => {
                          
                          if (key === action.payload.product) {
                            return {
                              ...acc,
                              [key]: {
                                ...val?.products[key],
                                rebate: parseInt(action.payload.rebate),
                              }
                            };
                          }
                          return acc;
                        }, val?.products ?? {})
                      };
                    }
                    return val;
                  }),
                };
              }
              return member;
            }),
          };
        default: 
          return state;
      }
    },
    {
      title: "",
      members: [{
        customerId,
        level: 0,
        values: []
      }],
      totals: [],
    }
  );

  useEffect(() => {
    if (isValid) {
      setPayload({
        type: "SHARED",
        payload: agreement
      });
    }
  }, [isValid]);

  useEffect(() => {
    const accTypes = accountTypes.filter((acc) => acc.type?.toLowerCase() !== "demo");
    const values = [];
    const totals = [];
    accTypes.forEach((acc) => {
      totals.push({
        accountTypeId: acc._id,
        rebate: null,
        commission: null,
      });
      values.push({
        accountTypeId: acc._id,
        rebate: null,
        commission: null,
        products: Object.keys(products).reduce((acc, key) => {
          return {
            ...acc,
            [products[key]]: {
              rebate: null,
              commission: null
            }
          };
        }, {})
      });
    });
    setAgreement({
      type: "INIT_AGREEMENT",
      payload: {
        agreement: {
          ...agreement,
          totals,
          members: [{
            ...agreement.members[0],
            values
          }],
        }
      }
    });     
  }, [accountTypes, products]);
  console.log(agreement);
  const validateForm = () => {
    const {
      title, totals, members
    } = agreement;
    if (title === "") {
      setIsValid(false);
      return setErrorMessage("Please Enter Agreement Title");
    }
    const isTotalValid = totals.every((total) =>{
      const { rebate, commission } = total;
      console.log(total);
      if (rebate === null || commission === null || rebate < 0 || commission < 0 || isNaN(rebate) || isNaN(commission)) {
        return false;
      }
      return true;
    });
    if (!isTotalValid) {
      setIsValid(false);
      return setErrorMessage("Please Enter Valid Total Values");
    }
    const isMemberValuesValid = members.every((member) => {
      const { values } = member;
      return values.every((val) => {
        const { rebate, commission, products } = val;
        if (rebate === null || commission === null || rebate < 0 || commission < 0 || isNaN(rebate) || isNaN(commission)) {
          return false;
        }
        return Object.keys(products).every((prod) => {
          const { rebate, commission } = products[prod];
          if (rebate === null || commission === null || rebate < 0 || commission < 0 || isNaN(rebate) || isNaN(commission)) {
            return false;
          }
          return true;
        });
      });
    });
    if (!isMemberValuesValid) {
      setIsValid(false);
      return setErrorMessage("Please Enter Valid Member Values");
    }
    const isSumValid = totals.every((total) => {
      const { accountTypeId } = total;
      const totalRebate = members.reduce((acc, member) => {
        const { values } = member;
        const memberTotal = values.find((val) => val.accountTypeId === accountTypeId);
        if (memberTotal) {
          return acc + memberTotal.rebate;
        }
        return acc;
      }, 0);
      const totalCommission = members.reduce((acc, member) => {
        const { values } = member;
        const memberTotal = values.find((val) => val.accountTypeId === accountTypeId);
        if (memberTotal) {
          return acc + memberTotal.commission;
        }
        return acc;
      }, 0);
      return totalRebate <= total.rebate && totalCommission <= total.commission;
    });
    if (!isSumValid) {
      setIsValid(false);
      return setErrorMessage("Member Values Sum is greater than Total Values");
    }
    const isProductExceeding = members.every((member) => {
      const { values } = member;
      return values.every((val) => {
        const { products } = val;
        return Object.keys(products).every((prod) => {
          const { rebate, commission } = products[prod];
          return rebate <= val.rebate && commission <= val.commission;
        });
      });
    });
    if (!isProductExceeding) {
      setIsValid(false);
      return setErrorMessage("Product Values Sum is greater than Member Values");
    }
    isTotalValid && isMemberValuesValid && isSumValid && isProductExceeding && setIsValid(true) & setErrorMessage("");
    return true;
  };

  useEffect(() => {
    validateForm();
  }, [agreement]);

  return (
    <AvForm className="my-3">
      <Row>
        <Col md={3} sm={12}>
          <AvField
            name={"title"}
            label={t("Agreement name")}
            className="mb-3"
            type="text"
            value={agreement.title}
            placeholder={t("Agreement name")}
            errorMessage={t("Title is Required!")}
            validate={{ required: { value: true } }}
            onChange={(e) => {
              setAgreement({
                type: "TITLE",
                value: e.target.value
              });
            }}
          />
        </Col>
        <Col md={9} sm={12} className="p-5">
          <Row className="justify-content-start">
            <Col md="3">{t("Type")}</Col>
            <Col>{t("Total Rebate")}</Col>
            <Col>{t("Total Commission")}</Col>
          </Row>
          {agreement?.totals?.map((type, accIdx) => (
            <Row key={type._id} className="my-1 align-items-center ">
              <Col md="3">
                {accountTypes && accountTypes.find((acc) => acc._id === type.accountTypeId)?.title}
                <AvField
                  name={`totals[${accIdx}].accountTypeId`}
                  value={type.accountTypeId}
                  type="hidden"
                />
              </Col>
              <Col>
                <AvField
                  className="mt-1"
                  name={`totals[${accIdx}].rebate`}
                  value={accIdx.rebate}
                  bsSize="sm"
                  type="number"
                  errorMessage={t("Invalid Rebate value!")}
                  validate={{
                    required: { value: true },
                    min: { value: 0 },
                  }}
                  onChange={(e) => {
                    setAgreement({
                      type: "UPDATE_TOTAL_REBATE",
                      payload: {
                        accountTypeId: type.accountTypeId,
                        rebate: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col>
                <AvField
                  className="mt-1"
                  name={`totals[${accIdx}].commission`}
                  bsSize="sm"
                  type="number"
                  value={accIdx.commission}
                  errorMessage={t("Invalid  Commission value!")}
                  validate={{
                    required: { value: true },
                    min: { value: 0 },
                  }}
                  onChange={(e) => {
                    setAgreement({
                      type: "UPDATE_TOTAL_COMMISSION",
                      payload: {
                        accountTypeId: type.accountTypeId,
                        commission: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <hr className="my-3" />
      <Row>
        <Accordion className="mb-3" alwaysOpen>
          {agreement.members &&
            agreement.members
              .sort((a, b) => (a.level > b.level ? 1 : -1))
              .map((member, memberIdx) => {
                return <>
                  <Accordion.Item key={member.customerId} eventKey={memberIdx}>
                    <Accordion.Header>
                      {member.customerId?.firstName} {member.customerId?.lastName}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion className="my-1" alwaysOpen>
                        {member && member?.values?.map((type, accIdx) => (
                          <Accordion.Item key={type.accountTypeId} eventKey={accIdx}>
                            <Accordion.Header>
                              <Row className="w-100 my-1 align-items-center my-0">
                                <Col md="3">
                                  {accountTypes && accountTypes.find((acc) => acc._id === type.accountTypeId)?.title}
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].accountTypeId`}
                                    value={type.accountTypeId}
                                    type="hidden"
                                  />
                                  <AvField
                                    name={`members[${memberIdx}].level`}
                                    value={String(member.level)}
                                    type="hidden"
                                  />
                                  <AvField
                                    name={`members[${memberIdx}].customerId`}
                                    value={member.customerId}
                                    type="hidden"
                                  />
                                </Col>
                                <Col>
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].rebate`}
                                    bsSize="sm"
                                    type="number"
                                    placeholder={t("Rebate")}
                                    errorMessage={t("Invalid value!")}
                                    validate={{
                                      required: { value: true },
                                      min: {
                                        value: 0,
                                        errorMessage: "Minimum is 0"
                                      }
                                    }}
                                    onChange={(e) => {
                                      setAgreement({
                                        type: "UPDATE_MEMBER_REBATE_TOTAL",
                                        payload: {
                                          accountTypeId: type.accountTypeId,
                                          rebate: parseInt(e.target.value),
                                          customerId: member.customerId,
                                        },
                                      });
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].commission`}
                                    bsSize="sm"
                                    type="number"
                                    placeholder={t("Commission")}
                                    errorMessage={t("Invalid value!")}
                                    validate={{
                                      required: { value: true },
                                      min: {
                                        value: 0,
                                        errorMessage: "Minimum is 0"
                                      }
                                    }}
                                    onChange={(e) => {
                                      setAgreement({
                                        type: "UPDATE_MEMBER_COMMISSION_TOTAL",
                                        payload: {
                                          accountTypeId: type.accountTypeId,
                                          commission: parseInt(e.target.value),
                                          customerId: member.customerId,
                                        },
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Accordion.Header>
                            <Accordion.Body>
                              {Object.keys(type?.products ?? {})?.map((prod, prodIdx) => (
                                <Row
                                  key={prodIdx}
                                  className="my-1 align-items-center"
                                > 
                                  <Col md="3">{
                                    products.find((p) => p?.toLowerCase() === prod?.toLowerCase())
                                  }</Col>
                                  <Col>
                                    <AvField
                                      name={`members[${memberIdx}].values[${accIdx}].products.${prod}.rebate`}
                                      bsSize="sm"
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                        min: {
                                          value: 0,
                                          errorMessage: "Minimum is 0"
                                        }
                                      }}
                                      placeholder={t("Rebate")}
                                      errorMessage={t("Invalid Product Rebate value!")}
                                      value={type?.rebate}
                                      onChange={(e) => {
                                        setAgreement({
                                          type: "UPDATE_MEMBER_REBATE_PRODUCT",
                                          payload: {
                                            accountTypeId: type.accountTypeId,
                                            rebate: parseInt(e.target.value),
                                            customerId: member.customerId,
                                            product: prod,
                                          },
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <AvField
                                      name={`members[${memberIdx}].values[${accIdx}].products.${prod}.commission`}
                                      bsSize="sm"
                                      type="number"
                                      placeholder={t("Commission")}
                                      errorMessage={t("Invalid Product Commission value!")}
                                      value={type?.commission}
                                      validate={{
                                        required: { value: true },
                                        min: {
                                          value: 0,
                                          errorMessage: "Minimum is 0"
                                        }
                                      }}
                                      onChange={(e) => {
                                        setAgreement({
                                          type: "UPDATE_MEMBER_COMMISSION_PRODUCT",
                                          payload: {
                                            accountTypeId: type.accountTypeId,
                                            commission: parseInt(e.target.value),
                                            customerId: member.customerId,
                                            product: prod,
                                          },
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ))}
                              <Row>
                                <Col md="3">Markup</Col>
                                <Col>
                                  <AvFieldSelect
                                    name={`members[${memberIdx}].values[${accIdx}].markup`}
                                    options={(markups || []).map((obj) => {
                                      return ({
                                        label: `${obj}`,
                                        value: obj
                                      });
                                    })}
                                  />
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </>;
              })}
        </Accordion>
      </Row>
      <Row className="mx-5">
        {
          !isValid && errorMessage && <Alert color="danger" >{errorMessage}</Alert>
        }
      </Row>
    </AvForm>
  );
}
export default memo(SubMasterAgreementForm);