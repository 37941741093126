import {
  call, put, takeEvery, delay, takeLatest
} from "redux-saga/effects";
// Login Redux States
import {
  FETCH_DISCOUNT_COUPOUNS_START,
  FETCH_DISCOUNT_COUPOUNS_ROLES_START,
  ADD_DISCOUNT_COUPOUNS_START,
  EDIT_DISCOUNT_COUPOUNS_PASS_START,
  EDIT_DISCOUNT_COUPOUNS_START,
  DELETE_DISCOUNT_COUPOUNS_START,
  GET_ASSIGNED_DISCOUNT_COUPOUNS_START,
  ASSIGN_AGENT_START,
  DISCOUNT_COUPOUN_DISABLE_2FA_SUCCESS,
  DISCOUNT_COUPOUN_DISABLE_2FA_FAIL,
  EDIT_TARGET_START
} from "./actionTypes";
import {
  fetchDiscountCoupounsSuccess,
  fetchDiscountCoupounsRolesSuccess,
  fetchDiscountCoupounsRolesError,
  fetchDiscountCoupounsError,
  addDiscountCoupounSuccess,
  addDiscountCoupounError,
  addDiscountCoupounClear,
  editDiscountCoupounDone,
  editDiscountCoupounError,
  editDiscountCoupounClear,
  deleteDiscountCoupounDone,
  getSalesAgentsSuccess,
  assignAgentSuccess,
  assignAgentError,
  assignAgentClear,
  getSalesAgentsFail,
  editTargetSuccess,
  editTargetFail,
} from "./actions";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

//Include Both Helper File with needed methods

import * as discountCoupounsApi from "../../apis/discountCoupouns";

function* fetchDiscountCoupouns(params) {

  try {
    const data = yield call(discountCoupounsApi.getDiscountCoupouns, params);
    yield put(fetchDiscountCoupounsSuccess(data));
  }
  catch (error) {
    yield put(fetchDiscountCoupounsError(error));
  }

}
function* fetchRoles(params) {

  try {
    const data = yield call(discountCoupounsApi.getRoles, params);
    // console.log("data");  
    // console.log(data);  
    yield put(fetchDiscountCoupounsRolesSuccess(data));
  }
  catch (error) {
    yield put(fetchDiscountCoupounsRolesError(error));
  }

}
function* addDiscountCoupoun(params) {
  try {
    console.log("params", params);
    const data = yield call(discountCoupounsApi.addDiscountCoupoun, params);
    const { result } = data;
    yield put(addDiscountCoupounSuccess(result));
    yield put(showSuccessNotification("DiscountCoupoun added successfully"));
    yield put(addDiscountCoupounClear());
  }
  catch (error) {
    yield put(addDiscountCoupounError(error));
    yield delay(2000);
    yield put(addDiscountCoupounClear());
  }


}

function* editDiscountCoupoun(params) {
  try {
    const data = yield call(discountCoupounsApi.editDiscountCoupoun, params);
    const { result } = data;
    yield put(editDiscountCoupounDone({
      result,
      id: params.payload.id
    }));
    yield put(showSuccessNotification("DiscountCoupoun updated successfully"));
    yield put(editDiscountCoupounClear());
  }
  catch (error) {
    yield put(editDiscountCoupounError({ error: error.message }));
    yield delay(2000);
    yield put(editDiscountCoupounClear());
  }
}
function* editDiscountCoupounPass(params) {
  try {
    const data = yield call(discountCoupounsApi.editDiscountCoupounPass, params);
    const { result } = data;
    yield put(editDiscountCoupounDone({
      result,
      id: params.payload.id
    }));
    yield delay(2000);
    yield put(editDiscountCoupounClear());
  }
  catch (error) {
    yield put(editDiscountCoupounError({ error: error.message }));
    yield delay(2000);
    yield put(editDiscountCoupounClear());
  }
}
function* deleteDiscountCoupoun(params) {
  try {
    const data = yield call(discountCoupounsApi.deleteDiscountCoupoun, params);
    const { result } = data;
    yield put(deleteDiscountCoupounDone({
      result,
      id: params.payload
    }));
    yield put(showSuccessNotification("DiscountCoupoun deleted successfully"));
  }
  catch (error) {
    yield put(deleteDiscountCoupounDone({ error: error.message }));
  }


}
function * fetchSalesAgent(params){
  try {
    const data = yield call(discountCoupounsApi.getAssignedDiscountCoupouns, params);
    const { result } = data || {};
    yield put(getSalesAgentsSuccess(result));
  } catch (error){
    yield put(getSalesAgentsFail());
    yield put(showErrorNotification("Error happened while fetching agents"));
  }
}
function * assignAgent(params){
  try {
    const data = yield call(discountCoupounsApi.assignSalesAgent, params);
    const { status } = data;
    if (status){
      yield put(assignAgentSuccess(data));
      yield put(assignAgentClear());
      yield put(showSuccessNotification("Agent has been assigned to the client successfully"));
    }
  } catch (error){
    yield put(assignAgentError());
    yield put(showErrorNotification("Error happened while assigning agent to clients"));
  }
}

function * disable2FA({ payload }) {
  try {
    const res = yield call(discountCoupounsApi.disable2FA, payload);
    if (res) {
      yield put({
        type: DISCOUNT_COUPOUN_DISABLE_2FA_SUCCESS,
      });
      yield put(showSuccessNotification("Two factor authentication disabled successfully"));
    }
  } catch (error) {
    yield put({
      type: DISCOUNT_COUPOUN_DISABLE_2FA_FAIL,
    });
    yield put(showErrorNotification(error.message));
  }
}

function * editTarget({ payload }) {
  try {
    const res = yield call(discountCoupounsApi.editTarget, payload);
    if (res) {
      yield put(editTargetSuccess(res));
      yield put(showSuccessNotification("Target changed successfully"));
    }
  } catch (error) {
    yield put(editTargetFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function* discountCoupounsSaga() {
  yield takeEvery(FETCH_DISCOUNT_COUPOUNS_START, fetchDiscountCoupouns);
  yield takeEvery(FETCH_DISCOUNT_COUPOUNS_ROLES_START, fetchRoles);
  yield takeEvery(ADD_DISCOUNT_COUPOUNS_START, addDiscountCoupoun);
  yield takeEvery(EDIT_DISCOUNT_COUPOUNS_START, editDiscountCoupoun);
  yield takeEvery(EDIT_DISCOUNT_COUPOUNS_PASS_START, editDiscountCoupounPass);
  yield takeEvery(DELETE_DISCOUNT_COUPOUNS_START, deleteDiscountCoupoun);
  yield takeLatest(GET_ASSIGNED_DISCOUNT_COUPOUNS_START, fetchSalesAgent);
  yield takeEvery(ASSIGN_AGENT_START, assignAgent);
  yield takeEvery(DISCOUNT_COUPOUN_DISABLE_2FA_FAIL, disable2FA);
  yield takeEvery(EDIT_TARGET_START, editTarget);
}

export default discountCoupounsSaga;
