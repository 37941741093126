import {
  FETCH_PLANS_START,
  FETCH_PLANS_SUCCESS,
  API_ERROR,
  ADD_PLANS_START,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  EDIT_PLAN_START,
  EDIT_PLAN_SUCCESS,
  DELETE_PLAN_START,
  DELETE_PLAN_SUCCESS,
  ADD_MODAL_CLEAR,
  EDIT_MODAL_CLEAR,
  DELETE_MODAL_CLEAR,
  ASSIGN_PLAN_TO_CUSTOMER_START,
  ASSIGN_PLAN_TO_CUSTOMER_SUCCESS,
  ASSIGN_PLAN_TO_CUSTOMER_FAIL
} from "./actionsType";
export const fetcPlansStart = (params)=>{
  return {
    type:FETCH_PLANS_START,
    payload:{ params }
  };
};
export const fetcPlansSuccess = (data)=>{
  return {
    type:FETCH_PLANS_SUCCESS,
    payload:data
  };
};
export const assignCstomerToPlanStart = (body)=>{
  return {
    type:ASSIGN_PLAN_TO_CUSTOMER_START,
    payload:body 
  };
};
export const assignCstomerToPlanSuccess = (data)=>{
  return {
    type:ASSIGN_PLAN_TO_CUSTOMER_SUCCESS,
    payload:data
  };
};
export const assignCstomerToPlanFail = ()=>{
  return {
    type:ASSIGN_PLAN_TO_CUSTOMER_FAIL,
    // payload:data
  };
};
export const addPlanStart = (body)=>{
  return {
    type:ADD_PLANS_START,
    payload:body 
  };
};
export const addPlanSuccess = (data)=>{
  return {
    type:ADD_PLAN_SUCCESS,
    payload:data
  };
};
export const addPlanFail = ()=>{
  return {
    type:ADD_PLAN_FAIL,
    // payload:data
  };
};
export const editPlanStart = (id, body)=>{
  return {
    type:EDIT_PLAN_START,
    payload:{
      id,
      body
    }
  };
};
export const editPlanSuccess = (data)=>{
  return {
    type:EDIT_PLAN_SUCCESS,
    payload:data 
  };
};
export const deletePlanStart = (id)=>{
  return {
    type:DELETE_PLAN_START,
    payload:{ id }
  };
};
export const deletePlanSuccess = (id)=>{
  return {
    type: DELETE_PLAN_SUCCESS,
    payload:{ id } 
  };
};
export const addModalClear = ()=>{
  return {
    type:ADD_MODAL_CLEAR
  };
};
export const editModalClear = ()=>{
  return {
    type:EDIT_MODAL_CLEAR,
  };
};
export const deleteModalClear = ()=>{
  return {
    type: DELETE_MODAL_CLEAR
  };
};