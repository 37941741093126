import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchPlans (params){
  try {
    const result = await axioHelper.get(`/plans?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}
export async function updatePlan ({ body, id }){

  const result = await axioHelper.patch(`/plans/${id}`, body);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}
export async function addPlan (payload){
  const result = await axioHelper.postFormData("/plans", payload);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
  
}
export async function assignPlanToCustomer (payload){
  const result = await axioHelper.postFormData("/plans/assign-to-customer", payload);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
  
}
export async function deletePlan ( id ){
  try {
    const result = await axioHelper.del(`/plans/${id}`);
    return result;
  } catch (error){
    throw new Error("Error happened while deleting data");
  }
}