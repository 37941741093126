
export const FETCH_DISCOUNT_COUPOUNS_START = "FETCH_DISCOUNT_COUPOUNS_START";
export const FETCH_DISCOUNT_COUPOUNS_SUCCESS = "FETCH_DISCOUNT_COUPOUNS_SUCCESS";
export const FETCH_DISCOUNT_COUPOUNS_ERROR = "FETCH_DISCOUNT_COUPOUNS_ERROR";

export const ADD_DISCOUNT_COUPOUNS_START = "ADD_DISCOUNT_COUPOUNS_START";
export const ADD_DISCOUNT_COUPOUNS_SUCCESS = "ADD_DISCOUNT_COUPOUNS_SUCCESS";
export const ADD_DISCOUNT_COUPOUNS_ERROR = "ADD_DISCOUNT_COUPOUNS_ERROR";
export const ADD_DISCOUNT_COUPOUN_CLEAR = "ADD_DISCOUNT_COUPOUN_CLEAR";

export const FETCH_DISCOUNT_COUPOUNS_ROLES_START = "FETCH_DISCOUNT_COUPOUNS_ROLES_START";
export const FETCH_DISCOUNT_COUPOUNS_ROLES_SUCCESS = "FETCH_DISCOUNT_COUPOUNS_ROLES_SUCCESS";
export const FETCH_DISCOUNT_COUPOUNS_ROLES_ERROR = "FETCH_DISCOUNT_COUPOUNS_ROLES_ERROR";

export const EDIT_DISCOUNT_COUPOUNS_PASS_START = "EDIT_DISCOUNT_COUPOUNS_PASS_START";
export const EDIT_DISCOUNT_COUPOUNS_START = "EDIT_DISCOUNT_COUPOUNS_START";
export const EDIT_DISCOUNT_COUPOUNS_DONE = "EDIT_DISCOUNT_COUPOUNS_DONE";
export const EDIT_DISCOUNT_COUPOUNS_ERROR = "EDIT_DISCOUNT_COUPOUNS_ERROR";
export const EDIT_DISCOUNT_COUPOUN_CLEAR = "EDIT_DISCOUNT_COUPOUN_CLEAR";

export const DELETE_DISCOUNT_COUPOUNS_START = "DELETE_DISCOUNT_COUPOUNS_START";
export const DELETE_DISCOUNT_COUPOUNS_DONE = "DELETE_DISCOUNT_COUPOUNS_DONE";
export const GET_ASSIGNED_DISCOUNT_COUPOUNS_START = "GET_ASSIGNED_DISCOUNT_COUPOUNS_START";
export const GET_ASSIGNED_DISCOUNT_COUPOUNS_SUCCESS = "GET_ASSIGNED_DISCOUNT_COUPOUNS_sUCCESS";
export const GET_ASSIGNED_DISCOUNT_COUPOUNS_FAIL = "GET_ASSIGNED_DISCOUNT_COUPOUNS_FAIL";
export const ASSIGN_AGENT_START = "ASSIGN_AGENT_START";
export const ASSIGN_AGENT_COMPLETE = "ASSIGN_AGENT_COMPLETE";
export const ASSIGN_AGENT_CLEAR = "ASSIGN_AGENT_CLEAR";
export const ASSIGN_AGENT_ERROR = "ASSIGN_AGENT_ERROR";

export const DISCOUNT_COUPOUN_DISABLE_2FA_START = "DISCOUNT_COUPOUN_DISABLE_2FA_START";
export const DISCOUNT_COUPOUN_DISABLE_2FA_SUCCESS = "DISCOUNT_COUPOUN_DISABLE_2FA_SUCCESS";
export const DISCOUNT_COUPOUN_DISABLE_2FA_FAIL = "DISCOUNT_COUPOUN_DISABLE_2FA_FAIL";

export const EDIT_TARGET_START = "EDIT_TARGET_START";
export const EDIT_TARGET_SUCCESS = "EDIT_TARGET_SUCCESS";
export const EDIT_TARGET_FAIL = "EDIT_TARGET_FAIL";
