import {
  FETCH_FEE_CONFIGURATIONS_START,
  FETCH_FEE_CONFIGURATIONS_SUCCESS,
  API_ERROR,
  ADD_FEES_CONFIGURATIONS_START,
  ADD_FEES_CONFIGURATION_SUCCESS,
  EDIT_FEE_CONFIGURATION_START,
  EDIT_FEE_CONFIGURATION_SUCCESS,
  DELETE_FEE_CONFIGURATION_START,
  DELETE_FEE_CONFIGURATION_SUCCESS,
  ADD_MODAL_CLEAR,
  EDIT_MODAL_CLEAR,
  DELETE_MODAL_CLEAR
} from "./actionsType";
export const fetchFeeConfigurationStart = (params)=>{
  return {
    type:FETCH_FEE_CONFIGURATIONS_START,
    payload:{ params }
  };
};
export const fetchFeeConfigurationsSuccess = (data)=>{
  return {
    type:FETCH_FEE_CONFIGURATIONS_SUCCESS,
    payload:data
  };
};
export const apiError = (error)=> {
  return {
    type:API_ERROR,
    payload:{ error }
  };
};
export const addFeesConfigurationStart = (body)=>{
  return {
    type:ADD_FEES_CONFIGURATIONS_START,
    payload:body 
  };
};
export const addFeeConfigurationSuccess = (data)=>{
  return {
    type:ADD_FEES_CONFIGURATION_SUCCESS,
    payload:data

  };
};
export const editFeeConfigurationStart = (id, body)=>{
  return {
    type:EDIT_FEE_CONFIGURATION_START,
    payload:{
      id,
      body
    }
  };
};
export const editFeeConfigurationSuccess = (data)=>{
  return {
    type:EDIT_FEE_CONFIGURATION_SUCCESS,
    payload:data 
  };
};
export const deleteFeeConfigurationStart = (id)=>{
  return {
    type:DELETE_FEE_CONFIGURATION_START,
    payload:{ id }
  };
};
export const deleteFeeConfigurationSuccess = (id)=>{
  return {
    type: DELETE_FEE_CONFIGURATION_SUCCESS,
    payload:{ id } 
  };
};
export const addModalClear = ()=>{
  return {
    type:ADD_MODAL_CLEAR
  };
};
export const editModalClear = ()=>{
  return {
    type:EDIT_MODAL_CLEAR,

  };
};
export const deleteModalClear = ()=>{
  return {
    type: DELETE_MODAL_CLEAR
  };
};