import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { 
  Form, FormGroup, Input, Col, Row, Label,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./CreatePlan.styles.scss";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import Loader from "components/Common/Loader";
import axios from "axios";
import { editPlanStart } from "store/actions";

const PrizeForm = (props) => {
  const {
    id,
    prizes,
    handleChangePrizeData,
    // removePhase,
    // addPhase,
    initialBalance,
  } = props;
  const { t } = useTranslation();
  const { accountTypes } = useSelector(state => state.tradingAccountReducer);
  const { editButtonDisabled, addSuccess, addedPlan } = useSelector((state) => state.planReducer);
  // const [editButtonDisabled, seteditButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  async function updatePrize() {
    try {
      const prize = prizes?.[0];
      const data = {
        "prize.amount": prize?.amount,
        "prize.isReadOnlyIfFailed": prize?.isReadOnlyIfFailed,
        "prize.calculateDailyLossFrom": prize?.calculateDailyLossFrom,
        "prize.withdrawedProfitValue": prize?.withdrawedProfitValue,
        "prize.targetDailyLoss": (prize?.amount * (prize?.targetDailyLoss / 100)),
        "prize.targetMaxLoss": (prize?.amount * (prize?.targetMaxLoss / 100)),
      };
      dispatch(editPlanStart(id, data));
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="my-5">
      {
        prizes && prizes?.map((prize, index) => (
          <Row key={prize?.id}>
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Amount")}</h5>
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="amount"
                      placeholder={`${t("Input Amount")}`}
                      type="number"
                      value={prize?.amount}
                      // disabled={id}
                      onChange={(e) => handleChangePrizeData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Read Only If Failed")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup switch>
                      <Input
                        name="isReadOnlyIfFailed"
                        type="switch"
                        checked={prize?.isReadOnlyIfFailed}
                        // disabled={id}
                        onClick={(e) => handleChangePrizeData(e.target.name, !prize?.isReadOnlyIfFailed, index)}
                      />
                    </FormGroup>
                    <Label>{t("Put account to read-only state if Phase is failed")}</Label>
                  </Form>
                </div>
              </div>
            </Col>
            
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Calculate Daily Loss From")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup className="d-flex align-items-center gap-3">
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDailyLossFrom"
                          id={`initial_balance${index}`}
                          checked={prize?.calculateDailyLossFrom === "initial_balance"}
                          value= "initial_balance"
                          // disabled={id}
                          onClick={(e) => handleChangePrizeData(e.target.name, "initial_balance", index)}
                        />
                        <Label htmlFor="initial_balance" className="mb-0">{t("Initial Balance")}</Label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="calculateDailyLossFrom"
                          id={`current_balance${index}`}
                          checked={prize?.calculateDailyLossFrom === "current_balance"}
                          value= "current_balance"
                          // disabled={id}
                          onClick={(e) => handleChangePrizeData(e.target.name, "current_balance", index)}
                        />
                        <Label htmlFor="current_balance" className="mb-0">{t("Current Balance")}</Label>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Withdrawed Profit Type")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="withdrawedProfitValue"
                      placeholder={`${t("Input Daily Loss")}`}
                      type="number"
                      value={prize?.withdrawedProfitValue}
                      // disabled={id}
                      onChange={(e) => Number(e.target.value) > 100 ? handleChangePrizeData(e.target.name, 100, index) : handleChangePrizeData(e.target.name, e.target.value, index)}
                      className="mb-0"
                    />
                  </div>
                  {/* <div className="createPlanInputValue">
                    = {prize?.amount * (prize?.withdrawedProfitValue / 100)} USD
                  </div> */}
                </div>
              </div>
            </Col>
            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Withdrawed Profit Type")}</h5>
                <div className="w-100">
                  <Form>
                    <FormGroup className="d-flex align-items-center gap-3">
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="withdrawedProfitValue"
                          id={`above_initial_balance${index}`}
                          checked={prize?.withdrawedProfitValue === "above_initial_balance"}
                          value= "above_initial_balance"
                          // disabled={id}
                          onClick={(e) => handleChangePrizeData(e.target.name, "above_initial_balance", index)}
                        />
                        <Label htmlFor="above_initial_balance" className="mb-0">{t("Above Initial Balance")}</Label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Input
                          type="radio"
                          name="withdrawedProfitValue"
                          id={`percentage${index}`}
                          checked={prize?.withdrawedProfitValue === "percentage"}
                          value= "percentage"
                          // disabled={id}
                          onClick={(e) => handleChangePrizeData(e.target.name, "percentage", index)}
                        />
                        <Label htmlFor="percentage" className="mb-0">{t("Percentage")}</Label>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col> */}

            <Col md="12">
              <p className="sectionSeperator">{t("Broker")}</p>
            </Col>
            
            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Group")}</h5>
                <div className="input-group w-100">
                  <div className="avFormInputWrapper">
                    <AvFieldSelect
                      name="brokerGroup"
                      placeholder={"Choose Account Group"}
                      options={accountTypes?.map(acc => ({
                        label: `${acc.title}`,
                        value: `${acc._id}`
                      }))}
                      value={prize?.brokerGroup}
                      // disabled={id}
                      onChange={(e) => handleChangePrizeData("brokerGroup", e, index)}
                    >
                    </AvFieldSelect>
                  </div>
                </div>
              </div>
            </Col> */}
            
            {/* <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Size")}</h5>
                <div className="input-group w-100">
                  <div className="avFormInputWrapper">
                    <AvField
                      name="brokerSize"
                      placeholder={`${t("Input Size")}`}
                      type="number"
                      onChange={(e) => handleChangePrizeData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col> */}

            <Col md="12">
              <p className="sectionSeperator">{t("Trading Objective Rules/Targets")}</p>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Daily Loss")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetDailyLoss"
                      placeholder={`${t("Input Daily Loss")}`}
                      type="number"
                      value={prize?.targetDailyLoss}
                      // disabled={id}
                      onChange={(e) => handleChangePrizeData(e.target.name, e.target.value, index)}
                      className="mb-0"
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {prize?.amount * (prize?.targetDailyLoss / 100)} USD
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Max Loss")}</h5>
                <div className="input-group w-100 createPlanInputGroup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      %
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="targetMaxLoss"
                      placeholder={`${t("Input Max Loss")}`}
                      type="number"
                      value={prize?.targetMaxLoss}
                      // disabled={id}
                      onChange={(e) => handleChangePrizeData(e.target.name, e.target.value, index)}
                    />
                  </div>
                  <div className="createPlanInputValue">
                    = {prize?.amount * (prize?.targetMaxLoss / 100)} USD
                  </div>
                </div>
              </div>
            </Col>
            <hr/>
          </Row>
        ))
      }
      <div
        className="btn-group "
        role="group"
        aria-labelledby="Basic outlined example"
      >
        {
          (id ) &&
            <button
              disabled={editButtonDisabled}
              type="button"
              className="btn btn-lg btn-primary btn_style  w-100 mx-4 px-4 text-white"
              onClick={() => updatePrize()}
              // onClick={() => {currentTab === "phases" ? setIsNav(false) : goToNextTab() }}
            >
              {
                !editButtonDisabled ? 
                  <><i className="fa fa-floppy-disk"></i> Update Prize</>
                  : <Loader />
              }
            </button>
        }
      </div>
      {/* {!id && <button type="button" onClick={addPhase} className="btn btn-success d-flex ms-auto my-2 px-4">{t("Add Phase")}</button>} */}
    </div>
  );
};

export default PrizeForm;
