import { 
  takeEvery, 
  call, 
  put,
  delay
} from "redux-saga/effects";
import 
{
  fetchPlans,
  updatePlan,
  deletePlan,
  addPlan,
  assignPlanToCustomer
} from "apis/plans";
import { 
  FETCH_PLANS_START,
  ADD_PLANS_START,
  EDIT_PLAN_START,
  DELETE_PLAN_START,
  ASSIGN_PLAN_TO_CUSTOMER_START
} from "./actionsType";
import { 
  addModalClear,
  editModalClear,
  deleteModalClear,
  addPlanSuccess,
  fetcPlansSuccess,
  editPlanSuccess,
  addPlanFail,
  deletePlanSuccess,
  deletePlanStart,
  assignCstomerToPlanSuccess,
  assignCstomerToPlanFail
} from "./actions";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import { apiError } from "store/actions";
function * getPlans({ payload :{ params } }){
  try {
    const result = yield call(fetchPlans, params);
    yield put(fetcPlansSuccess(result));
  } catch (error){
    yield put(apiError(error));
  }
}
function * addNewPlan ({ payload }){
  
  try {
    const data = yield call(addPlan, payload);
    const { status, result, message } = data;
    if (status === true){
      yield put(addPlanSuccess(result));
      yield put(showSuccessNotification("Plan has been added successfully!"));
      yield delay(1000);
      yield put(addModalClear());
    } else {
      yield put(addPlanFail());
      yield put(apiError(message));
      yield put(showErrorNotification(message));
      yield delay(2000);
      yield put(apiError(""));
    }
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}

function * assignCustomerToPlan ({ payload }){
  try {
    const data = yield call(assignPlanToCustomer, payload);
    const { status, result, message } = data;
    if (status === true){
      yield put(assignCstomerToPlanSuccess(result));
      yield put(showSuccessNotification("Assign Customer To Plan successfully!"));
      yield delay(1000);
      yield put(addModalClear());
    } else {
      yield put(assignCstomerToPlanFail());
      yield put(apiError(message));
      yield put(showErrorNotification(message));
      yield delay(2000);
      yield put(apiError(""));
    }
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * editPlan({ payload }){
  try {
    console.log("payload", payload);
    yield call(updatePlan, payload);
    const { body, id } = payload;
    yield put(editPlanSuccess({
      _id:id, 
      ...body
    }));
    yield put(editModalClear());
    yield put(showSuccessNotification("Plan has been updated successfully!"));
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * deletePlans ({ payload : { id } }){
  try {
    // yield put(deletePlanStart());
    yield call(deletePlan, id);
    yield put(deletePlanSuccess(id));
    yield put(showSuccessNotification("Plan has been deleted successfully!"));
    yield delay(1000);
    yield put(deleteModalClear());
  } catch (error){
    yield put(apiError(error));
    yield delay(1000);
    yield put(apiError(""));
  }
}
function * PlanSaga(){
  yield takeEvery(FETCH_PLANS_START, getPlans);
  yield takeEvery(ASSIGN_PLAN_TO_CUSTOMER_START, assignCustomerToPlan);
  yield takeEvery (ADD_PLANS_START, addNewPlan);
  yield takeEvery(EDIT_PLAN_START, editPlan);
  yield takeEvery (DELETE_PLAN_START, deletePlans);
}
export default PlanSaga;