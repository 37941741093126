import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { 
  Form, FormGroup, Input, Col, Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./CreatePlan.styles.scss";
import { useParams } from "react-router-dom";

const FeaturesForm = (props) => {
  const {
    id,
    features,
    handleChangeFeatureData,
    removeFeature,
    addFeature,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="my-5">
      {
        features && features?.map((feature, index) => (
          <Row key={feature?.id}>
            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Title")}</h5>
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="title"
                      placeholder={`${t("Input Title")}`}
                      type="text"
                      disabled={id}
                      value={feature?.title}
                      onChange={(e) => handleChangeFeatureData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div className="mb-3 d-flex align-items-center gap-2">
                <h5 className="mb-2 createPlanInputTitle">{t("Icon")}</h5>
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <div className="avFormInputWrapper">
                    <AvField
                      name="icon"
                      placeholder={`${t("Input Icon")}`}
                      type="text"
                      value={feature?.icon}
                      disabled={id}
                      onChange={(e) => handleChangeFeatureData(e.target.name, e.target.value, index)}
                    />
                  </div>
                </div>
              </div>
            </Col>
            {
              !id && 
              <Col md="12">
                <button
                  type="button"
                  className="btn btn-danger d-flex ms-auto mb-2 px-4"
                  onClick={()=>removeFeature(feature?.id)}
                >
                  {t("Remove Feature")}
                </button>
              </Col>
            }
            <hr/>
          </Row>
        ))
      }
      {
        !id && <button type="button" onClick={addFeature} className="btn btn-success d-flex ms-auto my-2 px-4">{t("Add Feature")}</button>
      }
    </div>
  );
};

export default FeaturesForm;
