import React, {
  memo, useCallback, useEffect
} from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Label,
  Modal, ModalBody, ModalHeader, Row, Spinner
} from "reactstrap";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { getClients } from "apis/client";
import {
  fetchAccountTypes, fetchAgreements, fetchProducts, showErrorNotification, showSuccessNotification
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import AddSubIbAgreement from "./AddSubIbAgreement";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";
import MasterAgreementForm from "./MasterAgreementForm";
import SubMasterAgreementForm from "./SubMasterAgreementForm";
import { approveIbRequest, approveIbRequestWithAgreement } from "apis/requests";

function IBApprovalModal({
  request, isOpen, toggle
}) {
  const dispatch = useDispatch();
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { agreements } = useSelector((state) => state?.ibAgreements);
  const { products } = useSelector((state) => state?.ibAgreements);
  const [loading, setLoading] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [agreement, setAgreement] = React.useState(null);
  const [type, setType] = React.useState(request?.parentRef ? "EXISTING" : "");
  const [newType, setNewType] = React.useState("");
  const [ib, setIB] = React.useState(request?.parentRef?._id || null); 
  const { t } = useTranslation();
  const [defaultOptions, setDefaultOptions] = React.useState([]);
  const [payload, setPayload] = React.useState({});
  useEffect(() => {
    const payload = {
      page: 1,
      limit: 1000,
      fxType: "IB"
    };
    getClients({ payload }).then((data) => {
      setDefaultOptions(data?.result?.docs?.filter(
        (client) => client._id !== request?.customerId?._id &&  client?.fx?.ibMT5Acc?.length > 0
      ).map((client) => (
        {
          label: `${client.firstName} ${client.lastName}`,
          value: `${client._id}`
        }
      )));
    });
  }, []);

  useEffect(() => {
    dispatch(fetchAccountTypes({
      type: "LIVE",
    }));
    dispatch(fetchProducts());
    // dispatch(getMT5Markups({ clientId: request.customerId }));
  }, []);

  useEffect(() => {
    ib && dispatch(fetchAgreements({ customerId: ib }));
  }, [ib]);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      getClients({
        payload: {
          searchText: inputValue,
          fxType: "IB",
          page: 1,
          limit: 10,
        }
      }).then((data) => {
        return cb(data?.result?.docs.filter(
          (client) => client._id !== request?.customerId?._id && client?.fx?.ibMT5Acc?.length > 0
        ).map((client) => (
          {
            label: `${client.firstName} ${client.lastName}`,
            value: `${client._id}`
          }
        )));
      });
    }, 1000), []);

  const handleApproval = () => {
    setLoading(true);
    approveIbRequest({
      ...payload,
      requestId: request._id,
    })
      .then(res => {
        if (res?.isSuccess) {
          toggle();
          return dispatch(showSuccessNotification("IB Approved Successfully"));
        }
        throw new Error(res?.message);
      }).catch(err =>{
        console.log(err);
        dispatch(showErrorNotification(err.message));
      }).finally(() => {
        setLoading(false);
      });    
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        {t("IB Approval")}
      </ModalHeader>
      <ModalBody className="p-3">
        {/* {(!request?.parentRef) && <Row>
          <ReactSelect
            className="react-select my-2"
            classNamePrefix="react-select"
            isDisabled={loading}
            isLoading={loading}
            options={[
              {
                label: "New Agreement",
                value: "NEW"
              }, {
                label: "Link to Existing IB",
                value: "EXISTING"
              }
            ]}
            onChange={(e) => {
              setType(e.value);
            }}
          />
        </Row>}
        <Row>
          {type === "NEW" && <>
            <Label>{t("Agreement")}</Label>
            <ReactSelect
              className="react-select my-2"
              classNamePrefix="react-select"
              isDisabled={loading}
              isLoading={loading}
              options={[
                {
                  label: "New Master Agreement",
                  value: "MASTER"
                }, {
                  label: "new Shared Agreement",
                  value: "SHARED"
                }
              ]}
              onChange={(e) => {
                setNewType(e.value);
              }}
            />
            
            {
              newType === "MASTER" && <MasterAgreementForm
                isValid={isFormValid}
                setIsValid={setIsFormValid}
                customerId={request?.customerId}
                setPayload={setPayload}
              />
            }
            {
              newType === "SHARED" && <SubMasterAgreementForm
                isValid={isFormValid}
                setIsValid={setIsFormValid}
                customerId={request?.customerId}
                setPayload={setPayload}
              />
            }
          </>
          }
          {type === "EXISTING" && !ib && <>
            <Label>{t("Select IB")}</Label>
            <AsyncSelect
              className="react-select my-2"
              classNamePrefix="react-select"
              isDisabled={loading}
              isLoading={loading}
              loadOptions={debouncedChangeHandler}
              defaultOptions={defaultOptions}
              onChange={(e) => {
                setIB(e.value);
              }}
            />
          </>
          }
        </Row>
        {type === "EXISTING" && ib && <Row>
          <Label>{t("Select Agreement")}</Label>
          <ReactSelect
            className="react-select my-2"
            classNamePrefix="react-select"
            isDisabled={loading}
            isLoading={loading}
            options={agreements?.map((agreement) => ({
              label: agreement.title,
              value: agreement
            }))}
            onChange={(e) => {
              setAgreement(e.value);
            }}
          />
        </Row>}
        {type === "EXISTING" && ib && agreement && <Row className="p-2">
          {!agreement?.isMaster ? <AddSubIbAgreement
            isValid={isFormValid}
            setIsValid={setIsFormValid}
            agg={{
              ...agreement,
              members: [
                ...agreement.members,
                {
                  customerId: request?.customerId,
                  level: agreement?.members?.length + 1,
                  values: agreement?.totals?.map((total) => ({
                    accountTypeId: total.accountTypeId,
                    rebate: null,
                    commission: null,
                    products: Object.keys(products).reduce((acc, key) => {
                      return {
                        ...acc,
                        [products[key]]: {
                          rebate: null,
                          commission: null
                        }
                      };
                    }, {})
                  }))
                }
              ]
            }}
            setPayload={setPayload}
          /> : <>
            <AgreementDetails
              agreement={agreement}
              accountTypes={accountTypes}
              t={t}
            />
            <div>
              <Alert color="warning">
                {t("This is a master agreement. You can not add any sub IBs to this agreement.")}
              </Alert>
            </div>
          </>}
        </Row>} */}
        <Row className="px-5 py-2">
          {loading ?
            <div className="d-flex justify-content-center">
              <Spinner />
            </div> : 
            <Button
              color="primary"
              className="w-100"
              onClick={() => {
                handleApproval();
              }}
              disabled={!isFormValid || loading}
            >
              {t("Approve")}
            </Button>
          }
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default memo(IBApprovalModal);