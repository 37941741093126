import { all, fork } from "redux-saga/effects";
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ResetPasswordSaga from "./auth/resetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import usersSaga from "./users/saga";
import twoFactorAuthSaga from "./auth/twoFactorAuth/saga";
import notificationsSaga from "./notifications/saga";
import dashboardSaga from "./dashboard/saga";
import leadSaga from "./leads/saga";
import clientSaga from "./client/saga";
import todosSaga from "./todos/saga";
import dictionarySaga from "./dictionary/saga";
import PlanSaga from "./plans/saga";
import LayoutSaga from "./layout/saga";
import tradingAccountsSaga from "./tradingAccounts/saga";
import systemEmailConfigSaga from "./systemEmailConfig/saga";
import requestSaga from "./requests/saga";
import depositSaga from "./transactions/saga";
import withdrawalSaga from "./transactions/withdrawal/saga";
import walletSaga from "./wallet/list/saga";
import gatewaySaga from "./gateway/saga";
import forexDepositSaga from "./forexTransactions/deposits/saga";
import forexGatewaySaga from "./forexGateway/saga";
import forexWithdrawalSaga from "./forexTransactions/withdrawals/saga";
import bankSaga from "./companyBankAccount/saga";
import bankAccountSaga from "./bankAccount/saga";
import assetSaga from "./assests/saga";
import subscriptionsSaga from "./subscriptions/saga";
import rolesSaga from "./roles/saga";

import teamsSaga from "./teams/saga";
import documentsSaga from "./documents/saga";
import logsSaga from "./logs/saga";
import convertSaga from "./converts/saga";
import ordersSaga from "./orders/saga";
import feeGroupSaga from "./feeGroups/saga";
import feeConfigurationSaga from "./feeConfiguration/saga";
import transactionFeeGroupSaga from "./transactionFeeGroups/saga";
import markupSaga from "./markups/saga";
import conversionRatesSaga from "./conversionRates/saga";
import campaignTemplatesaga from "./CampaignTemplates/saga";
import emailCampaignsaga from "./EmailCampaigns/saga";
import campaignUnsubscribers from "./CampaignUnsubscribers/saga";
import systemEmailsSaga from "./systemEmail/saga";
import discountCoupounsSaga from "./discountCoupouns/saga";
import reportsSaga from "./reports/saga";

export default function* rootSaga() {
  yield all([
  //public
    fork(LayoutSaga),
    fork(discountCoupounsSaga),
    fork(PlanSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(twoFactorAuthSaga),
    fork(systemEmailsSaga),
    fork(leadSaga),
    fork(ForgetSaga),
    fork(ResetPasswordSaga),
    fork(ProfileSaga),
    fork(usersSaga),
    fork(notificationsSaga),
    fork(clientSaga),
    fork(todosSaga),
    fork(dictionarySaga),
    fork(systemEmailConfigSaga),
    fork(tradingAccountsSaga),
    fork(bankSaga),
    fork(depositSaga),
    fork(dashboardSaga),
    fork(withdrawalSaga),
    fork(walletSaga),
    fork(gatewaySaga),
    fork(withdrawalSaga),
    fork(forexDepositSaga),
    fork(forexGatewaySaga),
    fork(forexWithdrawalSaga),
    fork(bankAccountSaga),
    fork(assetSaga),
    fork(reportsSaga),
    fork(teamsSaga),
    fork(documentsSaga),
    fork(logsSaga),
    fork(convertSaga),
    fork(requestSaga),
    fork(ordersSaga),
    fork(feeGroupSaga),
    fork(feeConfigurationSaga),
    fork(transactionFeeGroupSaga),
    fork(markupSaga),
    fork(conversionRatesSaga),
    fork(campaignTemplatesaga),
    fork(emailCampaignsaga),
    fork(campaignUnsubscribers),
    fork(subscriptionsSaga),
    fork(rolesSaga),
  ]);
}