

import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getDiscountCoupouns = async ({ payload }) => {
  const data = await axiosHelper.get(`/dicount-coupouns?${qs.stringify(payload)}`);
  return data.result;
};
export const getAssignedDiscountCoupouns = async ({ payload }) => {
  const data = await axiosHelper.get(`/dicount-coupouns/assignable?${qs.stringify(payload)}`);
  return data;
};
export const getRoles = async ({ payload }) => {
  const data = await axiosHelper.get(`/roles?${qs.stringify(payload)}`);
  return data.result;
};
export const addDiscountCoupoun = async ({ payload }) => {
  const data = await axiosHelper.post("/dicount-coupouns", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const editDiscountCoupoun = async ({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/dicount-coupouns/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const editDiscountCoupounPass = async ({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/dicount-coupouns/${id}/password`, values);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const resetDiscountCoupounPass = async ({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/dicount-coupouns/${id}/password-change`, values);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const deleteDiscountCoupoun = async ({ payload }) => {
  const data = await axiosHelper.del(`/dicount-coupouns/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const assignSalesAgent = async ({ payload }) => {
  // /dicount-coupouns for multiple assigns from client list, /clients for single change from client profile
  const url = payload?.clientId ? "/clients/assign" : "/dicount-coupouns/assign";
  const data = await axiosHelper.post(url, payload);
  return data;
};

export const checkDiscountCoupounEmailApi = async (payload) => {
  const data = await axiosHelper.get(`/dicount-coupouns/check-email?${qs.stringify(payload, { encode: false })}`);
  return data;
};

export const disable2FA = async (payload) => {
  const { id } = payload;
  const res = await axiosHelper.post("/dicount-coupouns/disable-2fa", { DiscountCoupounId: id });
  if (res.status)
    return res.status;
  else throw new Error(res.message);
};

export const editTarget = async (payload) => {
  const res = await axiosHelper.patch("/target/", payload);
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};

export const getTarget = async (payload) => {
  const res = await axiosHelper.get(`/target?${qs.stringify(payload)}`);
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};

export const getCanBeAssignedDiscountCoupounTargets = async () => {
  const res = await axiosHelper.get("/target/all");
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};

export const editAllTargets = async (payload) => {
  const res = await axiosHelper.patch("/target/all", payload);
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};

export const editDiscountCoupounSettings = async (payload) => {
  const res = await axiosHelper.patch("/dicount-coupouns/settings", payload);
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};

export const sendDiscountCoupounEmail = async (payload) => {
  const res = await axiosHelper.post("/dicount-coupouns/send-email", payload);
  if (res.status)
    return res.result;
  else throw new Error(res.message);
};