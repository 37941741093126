import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { 
  Form, FormGroup, Label, Input
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addPlanStart } from "store/plans/actions";
import Loader from "components/Common/Loader";
import { useHistory, useParams } from "react-router-dom";
import BaseForm from "./CreatePlan/BaseForm";
import FeaturesForm from "./CreatePlan/FeaturesForm";
import { v4 as uuidv4 } from "uuid";
import PhasesForm from "./CreatePlan/PhasesForm";
import CertificateForm from "./CreatePlan/CertificateForm";
import { useTranslation } from "react-i18next";
import * as axiosHelper from "../../apis/api_helper";
import DiscountCoupounsForm from "./CreatePlan/DiscountCoupounsForm";
import { showErrorNotification } from "store/actions";
import { fetchAllReferrals } from "store/client/actions";
import PrizeForm from "./CreatePlan/PrizeForm";

const FEATURE_MODEL = (feature)=>{
  return {
    id:feature?._id || uuidv4(),
    title:feature?.title || "",
    icon:feature?.icon || "",
  };
};

const DISCOUNT_COUPOUN_MODEL = (discountCoupoun)=>{
  return {
    id:discountCoupoun?._id || uuidv4(),
    type:discountCoupoun?.type || "percentage",
    code:discountCoupoun?.code || "",
    value:discountCoupoun?.value || "",
  };
};

const PHASE_MODEL = (phase)=>{
  return {
    id:phase?._id || uuidv4(),
    title:phase?.title || "",
    isWritable:phase?.isWritable || false,
    isVerificationRequired:phase?.isVerificationRequired || false,
    isReadOnlyIfFailed:phase?.isReadOnlyIfFailed || false,
    calculateDaysAs:phase?.calculateDaysAs || "",
    calculateDailyLossFrom:phase?.calculateDailyLossFrom || "",
    inactiveDays:phase?.inactiveDays || "",

    brokerGroup:phase?.brokerGroup || "",
    brokerSize:phase?.brokerSize || "",
    
    dateType:phase?.dateType || "",
    startingDate:phase?.startingDate || "",
    endingDate:phase?.endingDate || "",
    
    targetDailyLoss:phase?.targetDailyLoss || "",
    targetMaxLoss:phase?.targetMaxLoss || "",
    targetProfitLoss:phase?.targetProfitLoss || "",
    targetMinTradingDays:phase?.targetMinTradingDays || "",
    targetMaxTradingDays:phase?.targetMaxTradingDays || "",
    targetMaxPositions:phase?.targetMaxPositions || "",
    targetMaxLots:phase?.targetMaxLots || "",
    targetIsStopLossReuired:phase?.targetIsStopLossReuired || "",
    targetIsExpertProhibited:phase?.targetIsExpertProhibited || false,
  };
};

const PRIZE_MODEL = (prize)=>{
  return {
    id:prize?._id || uuidv4(),
    amount:prize?.amount || "",
    isReadOnlyIfFailed:prize?.isReadOnlyIfFailed || false,
    calculateDailyLossFrom:prize?.calculateDailyLossFrom || "",
    withdrawedProfitValue:prize?.withdrawedProfitValue || "",
    targetDailyLoss:prize?.targetDailyLoss || "",
    targetMaxLoss:prize?.targetMaxLoss || "",
  };
};
const Platforms = ["MT4", "MT5", "CTRADER"];
const CreatePlan = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [plan, setPlan] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [initialBalance, setInitialBalance] = useState("");
  const [isPriceActivated, setIsPriceActivated] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isVerificationReuired, setIsVerificationReuired] = useState(false);
  const [features, setFeatures] = useState([]);
  const [phases, setPhases] = useState([]);
  const [prizes, setPrizes] = useState([PRIZE_MODEL()]);
  const [certificate, setCertificate] = useState([]);
  const [discountCoupouns, setDiscountCoupouns] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [payoutType, setPayoutType] = useState([]);
  const [assignType, setAssignType] = useState("all");
  const [referralClients, setReferralClients] = useState();
  const [payoutValue, setPayoutValue] = useState("");
  const [brokerGroup, setBrokerGroup] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { addButtonDisabled, addSuccess, addedPlan } = useSelector((state) => state.planReducer);
  const { allReferrals } = useSelector((state) => state.clientReducer.clientDetails);
  const userDetails = useSelector((state) => state?.Profile);
  const state = useSelector((state) => state);
  const [currentTab, setCurrentTab] = useState("base");
  const [isNav, setIsNav] = useState(false);

  const handleSelect = (key) => {
    setCurrentTab(key);
  };

  // const goToNextTab = () => {
  //   if (currentTab === "base") {
  //     setCurrentTab("discountCoupouns");
  //   } else if (currentTab === "discountCoupouns") {
  //     setCurrentTab("features");
  //   } else if (currentTab === "features") {
  //     setCurrentTab("phases");
  //   }
  // };
  useEffect(()=> {
    dispatch(fetchAllReferrals());
  }, []);

  const goToNextTab = () => {
    if (currentTab === "base") {
      setCurrentTab("features");
    } else if (currentTab === "features") {
      setCurrentTab("phases");
    } else if (currentTab === "phases") {
      setCurrentTab("prizes");
    }
  };

  const handleAddPlan =  (e, v) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", v?.title);
    formData.append("description", v?.description);
    formData.append("price", parseFloat(v.price ?? 0));
    formData.append("initialBalance", parseFloat(v.initialBalance ?? 0));
    formData.append("currency", v?.currency);
    formData.append("helpPost", v?.helpPost);
    formData.append("isPriceActivated", isPriceActivated);
    formData.append("isPublic", isPublic);
    formData.append("isEnabled", isEnabled);
    formData.append("brokerGroup", brokerGroup);
    formData.append("isVerificationReuired", isVerificationReuired);
    formData.append("certificate", certificate);

    formData.append("amount", amount);
    // formData.append("payoutType", payoutType);
    // formData.append("payoutValue", v?.payoutValue);

    // formData.append("assignType", assignType);
    // referralClients && formData.append("referralClients", referralClients);
    
    discountCoupouns?.forEach((discountCoupouns, index)=>{
      discountCoupouns?.type && formData.append(`discountCoupouns[${index}][type]`, discountCoupouns?.type);
      discountCoupouns?.value && formData.append(`discountCoupouns[${index}][value]`, discountCoupouns?.value);
      discountCoupouns?.code && formData.append(`discountCoupouns[${index}][code]`, discountCoupouns?.code);
    });
    features?.forEach((feature, index)=>{
      feature?.title && formData.append(`features[${index}][title]`, feature?.title);
      feature?.icon && formData.append(`features[${index}][icon]`, feature?.icon);
    });
    platforms?.forEach((platform, index)=>{
      formData.append("platforms", platform);
    });
    phases?.forEach((phase, index)=>{
      phase?.phase_title && formData.append(`phases[${index}][title]`, phase?.phase_title);
      phase?.isWritable && // formData.append(`phases[${index}][isWritable]`, phase?.isWritable);
      phase?.isVerificationRequired && // formData.append(`phases[${index}][isVerificationRequired]`, phase?.isVerificationRequired);
      phase?.isReadOnlyIfFailed && formData.append(`phases[${index}][isReadOnlyIfFailed]`, phase?.isReadOnlyIfFailed);
      phase?.calculateDaysAs && // formData.append(`phases[${index}][calculateDaysAs]`, phase?.calculateDaysAs);
      phase?.calculateDailyLossFrom && formData.append(`phases[${index}][calculateDailyLossFrom]`, phase?.calculateDailyLossFrom);
      phase?.inactiveDays && formData.append(`phases[${index}][inactiveDays]`, phase?.inactiveDays);
      phase?.brokerGroup && formData.append(`phases[${index}][brokerGroup]`, phase?.brokerGroup);
      phase?.brokerSize && formData.append(`phases[${index}][brokerSize]`, phase?.brokerSize);
      phase?.targetDailyLoss && formData.append(`phases[${index}][targetDailyLoss]`, (initialBalance * (phase?.targetDailyLoss / 100)));
      phase?.targetMaxLoss && formData.append(`phases[${index}][targetMaxLoss]`, (initialBalance * (phase?.targetMaxLoss / 100)));
      phase?.targetProfitLoss && formData.append(`phases[${index}][targetProfitLoss]`, (initialBalance * (phase?.targetProfitLoss / 100)));
      phase?.targetMinTradingDays && formData.append(`phases[${index}][targetMinTradingDays]`, phase?.targetMinTradingDays);
      phase?.targetMaxTradingDays && formData.append(`phases[${index}][targetMaxTradingDays]`, phase?.targetMaxTradingDays);
      phase?.targetMaxPositions && formData.append(`phases[${index}][targetMaxPositions]`, phase?.targetMaxPositions);
      phase?.targetMaxLots && formData.append(`phases[${index}][targetMaxLots]`, phase?.targetMaxLots);
      
      phase?.dateType && formData.append(`phases[${index}][dateType]`, phase?.dateType);
      phase?.startingDate && formData.append(`phases[${index}][startingDate]`, phase?.startingDate);
      phase?.endingDate && formData.append(`phases[${index}][endingDate]`, phase?.endingDate);
      
      phase?.targetIsStopLossReuired && // formData.append(`phases[${index}][targetIsStopLossReuired]`, phase?.targetIsStopLossReuired);
      phase?.targetIsExpertProhibited && formData.append(`phases[${index}][targetIsExpertProhibited]`, phase?.targetIsExpertProhibited);
    });
    const prize = prizes?.[0];
    prize?.amount && formData.append("prize[amount]", prize?.amount);
    prize?.isReadOnlyIfFailed && formData.append("prize[isReadOnlyIfFailed]", prize?.isReadOnlyIfFailed);
    prize?.calculateDailyLossFrom && formData.append("prize[calculateDailyLossFrom]", prize?.calculateDailyLossFrom);
    prize?.withdrawedProfitValue && formData.append("prize[withdrawedProfitValue]", prize?.withdrawedProfitValue);
    prize?.targetDailyLoss && formData.append("prize[targetDailyLoss]", (initialBalance * (prize?.targetDailyLoss / 100)));
    prize?.targetMaxLoss && formData.append("prize[targetMaxLoss]", (initialBalance * (prize?.targetMaxLoss / 100)));
    
    dispatch(addPlanStart(formData));
    setIsSubmitted(true);
    // goToNextTab();
  };
  
  const getPlan = async ()=>{
    const result = await axiosHelper.get(`/plans/${id}`);
    if (result?.isError)
      throw new Error(result?.message);
    else setPlan(result?.result);
  };

  useEffect(async()=>{
    if (addSuccess){
      setTimeout(()=>{
        history.push("/plans");
      }, 500);
    }
  }, [addSuccess]);

  useEffect(()=>{
    if (id){
      getPlan();
    }
  }, []);

  useEffect(()=>{
    if (plan){
      setTitle(plan?.title);
      setDescription(plan?.description);
      setPrice(plan?.price);
      setInitialBalance(plan?.initialBalance);
      setIsPublic(plan?.isPublic);
      setIsEnabled(plan?.isEnabled);
      setIsPriceActivated(plan?.isPriceActivated);
      setIsVerificationReuired(plan?.isVerificationRequired);
      setBrokerGroup(plan?.brokerGroup);
      setPlatforms(plan?.platforms);
      setAmount(plan?.amount);

      setAssignType(plan?.assignType);

      setPayoutType(plan?.payoutType);
      setPayoutValue(plan?.payoutValue);

      let features = plan?.features?.map((feature)=>{
        return FEATURE_MODEL(feature);
      });
      setFeatures(features);

      let discountCoupouns = plan?.discountCoupouns?.map((discountCoupoun)=>{
        return DISCOUNT_COUPOUN_MODEL(discountCoupoun);
      });
      setDiscountCoupouns(discountCoupouns);
      let phases = plan?.phases?.map(phase=>{
        return {
          ...phase,
          targetDailyLoss: (phase?.targetDailyLoss / plan?.initialBalance) * 100,
          targetMaxLoss: (phase?.targetMaxLoss / plan?.initialBalance) * 100,
          targetProfitLoss: (phase?.targetProfitLoss / plan?.initialBalance) * 100,
        };
      });
      phases = phases?.map((phase)=>{
        return PHASE_MODEL(phase);
      });
      setPhases(phases);
      let prize = {
        ...plan?.prize,
        targetDailyLoss: (plan?.prize?.targetDailyLoss * 100) / plan?.prize?.amount,
        targetMaxLoss: (plan?.prize?.targetMaxLoss * 100) / plan?.prize?.amount,
        targetProfitLoss: (plan?.prize?.targetProfitLoss * 100) / plan?.prize?.amount,
      };
      prize = PRIZE_MODEL(prize);
      setPrizes([prize]);
    }
  }, [plan]);

  function addDiscountCoupoun(){
    let discountCoupoun = [...discountCoupouns];
    discountCoupoun.push(FEATURE_MODEL({}));
    setDiscountCoupouns(discountCoupoun);
  }
  function removeDiscountCoupoun(id){
    let discountCoupoun = [...discountCoupouns];
    discountCoupoun = discountCoupoun.filter(feature=>feature?.id != id);
    setDiscountCoupouns(discountCoupoun);
  }
  function handleChangeDiscountCoupounData(key, value, index){
    let discountCoupoun = [...discountCoupouns];
    discountCoupoun[index][key] = value;
    if (discountCoupoun[index]["type"] === "percentage") {
      if (discountCoupoun[index]["value"] > 100) {
        discountCoupoun[index]["value"] = 100;
        dispatch(showErrorNotification("Discount Type Percentage Max Value is 100%"));
      }
    }
    setDiscountCoupouns(discountCoupoun);
  }

  function addFeature(){
    let featureData = [...features];
    featureData.push(FEATURE_MODEL({}));
    setFeatures(featureData);
  }
  function removeFeature(id){
    let featureData = [...features];
    featureData = featureData.filter(feature=>feature?.id != id);
    setFeatures(featureData);
  }
  function handleChangeFeatureData(key, value, index){
    let featureData = [...features];
    featureData[index][key] = value;
    setFeatures(featureData);
  }

  function addPhase(){
    let phasesData = [...phases];
    phasesData.push(PHASE_MODEL({}));
    setPhases(phasesData);
  }
  function removePhase(id){
    let phasesData = [...phases];
    phasesData = phasesData.filter(phase=>phase?.id != id);
    setPhases(phasesData);
  }
  function handleChangePhaseData(key, value, index){
    let phasesData = [...phases];
    phasesData[index][key] = value;
    setPhases(phasesData);
  }
  function handleChangePrizeData(key, value, index){
    let prizesData = [...prizes];
    prizesData[index][key] = value;
    setPrizes(prizesData);
  }

  return (
    
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
        
          <div className="d-flex justify-content-center align-items-center ">
            <div className="p-5 w-75 shadow-lg my-5 mt-4">
              {/* Top Page title  */}
              <div className="d-flex justify-content-between align-items-center">
                <h4>{t("Create")}</h4>
                <div
                  className="btn-group "
                  role="group"
                  aria-labelledby="Basic outlined example"
                >
                  {/* <button
                    type="button"
                    className="btn btn-lg btn-outline-primary btn_style"
                  >
                    <i className="fa fa-bars mx-3"></i> List
                  </button> */}
                </div>
              </div>
              {/* Tabs  */}
              <AvForm
                className='p-4'
                onValidSubmit={(e, v) => {
                  handleAddPlan(e, v);
                }}
              >
                <Tabs
                  defaultActiveKey="base"
                  id="uncontrolled-tab-example"
                  className="mb-3 my-5 mt-5"
                  onSelect={handleSelect}
                  activeKey={currentTab}
                >
                  <Tab eventKey="base" title="Base">
                    <BaseForm 
                      setIsPriceActivated={setIsPriceActivated}
                      setIsPublic={setIsPublic}
                      setIsEnabled={setIsEnabled}
                      setIsVerificationReuired={setIsVerificationReuired}
                      id={id}
                      payoutValue={payoutValue}
                      assignType={assignType}
                      setAssignType={setAssignType}
                      setReferralClients={setReferralClients}
                      referralClients={referralClients}
                      allReferrals={allReferrals}
                      payoutType={payoutType}
                      setPayoutType={setPayoutType}
                      isPriceActivated={isPriceActivated}
                      isPublic={isPublic}
                      isEnabled={isEnabled}
                      title={title}
                      description={description}
                      price={price}
                      amount={amount}
                      setAmount={setAmount}
                      initialBalance={initialBalance}
                      setInitialBalance={setInitialBalance}
                      brokerGroup={brokerGroup}
                      isVerificationReuired={isVerificationReuired}
                      setBrokerGroup={setBrokerGroup}
                      setPlatforms={setPlatforms}
                      platforms={Platforms}
                      platformsData={platforms}
                    />
                  </Tab>
                  {/* <Tab eventKey="discountCoupouns" title="Discount Coupouns">
                    <DiscountCoupounsForm 
                      id={id}
                      discountCoupouns={discountCoupouns}
                      handleChangeDiscountCoupounData={handleChangeDiscountCoupounData}
                      removeDiscountCoupoun={removeDiscountCoupoun}
                      addDiscountCoupoun={addDiscountCoupoun}
                    />
                  </Tab> */}
                  <Tab eventKey="features" title="Features">
                    <FeaturesForm 
                      id={id}
                      features={features}
                      handleChangeFeatureData={handleChangeFeatureData}
                      removeFeature={removeFeature}
                      addFeature={addFeature}
                    />
                  </Tab>
                  <Tab eventKey="phases" title="Phases">
                    <PhasesForm 
                      id={id}
                      phases={phases}
                      handleChangePhaseData={handleChangePhaseData}
                      removePhase={removePhase}
                      addPhase={addPhase}
                      initialBalance={initialBalance}
                    />
                  </Tab>
                  {
                    isPriceActivated && 
                      <Tab eventKey="prizes" title="Prizes">
                        <PrizeForm 
                          id={id}
                          prizes={prizes}
                          handleChangePrizeData={handleChangePrizeData}
                          // removePhase={removePhase}
                          // addPhase={addPhase}
                          initialBalance={initialBalance}
                        />
                      </Tab>
                  }
                  {/* <Tab eventKey="certuficate" title="Certificate">
                    <CertificateForm
                      setCertificate={setCertificate}
                    />
                  </Tab> */}
                </Tabs>
                
                <div className="d-flex justify-content-between align-items-center mt-5">
                  {/* <div
                    className="btn-group "
                    role="group"
                    aria-labelledby="Basic outlined example"
                  >
                    <button
                      type="button"
                      className="btn btn-lg btn-outline-primary btn_style  w-100 mx-4 px-4"
                    >
                      <i className="fa fa-rotate-right"></i> Reset
                    </button>
                  </div> */}

                  <div
                    className="btn-group "
                    role="group"
                    aria-labelledby="Basic outlined example"
                  >
                    {
                      (!id && (isPriceActivated ? currentTab === "prizes" : currentTab === "phases")) &&
                        <button
                          disabled={addButtonDisabled}
                          type="submit"
                          className="btn btn-lg btn-primary btn_style  w-100 mx-4 px-4 text-white"
                          // onClick={() => setIsNav( currentTab === "phases" ? true : false)}
                          // onClick={() => {currentTab === "phases" ? setIsNav(false) : goToNextTab() }}
                        >
                          {
                            !addButtonDisabled ? 
                              <><i className="fa fa-floppy-disk"></i> Submit</>
                              : <Loader />
                          }
                        </button>
                    }
                  </div>
                </div>
              </AvForm>
              <div
                className="btn-group "
                role="group"
                aria-labelledby="Basic outlined example"
              >
                {
                  ( isPriceActivated ? currentTab !== "prizes" : currentTab !== "phases") &&
                    <button
                      disabled={addButtonDisabled}
                      type="button"
                      className="btn btn-lg btn-primary btn_style  w-fit mx-4 px-4 text-white"
                      // onClick={() => setIsNav( currentTab === "phases" ? true : false)}
                      onClick={() => {goToNextTab() }}
                    >
                      {
                        !addButtonDisabled ? 
                          <><i className="fa fa-floppy-disk"></i> Next</>
                          : <Loader />
                      }
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreatePlan;
